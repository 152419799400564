import React from "react";
import {
  Stack,
  InputLeftAddon,
  Input,
  InputRightElement,
  Text,
  IconButton,
  InputGroup,
  Spinner,
  FormLabel,
  InputLeftElement,
} from "@chakra-ui/react";
import { FiEyeOff, FiEye, FiLock } from "react-icons/fi";

function CustomInput({
  value,
  onChange,
  name,
  type,
  onBlur,
  label,
  touched,
  errors,
  placeholder,
  isLoading = false,
  ...others
}) {
  const [show, setShow] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const handleClick = () => setShow(!show);

  React.useEffect(() => {
    if (type === "amount") {
      setDisabled(true);
    }
  }, [type]);

  const enableButton = () => setDisabled(false);

  return (
    <Stack w="full" pos="relative" alignItems="start">
      {/* The labe */}
      <FormLabel
        textAlign="left"
        fontWeight="bold"
        as="label"
        htmlFor={name}
        mb={-1}
        color="#68768B"
        fontSize="sm"
        textTransform="capitalize"
      >
        {label}
      </FormLabel>

      {/* Input group */}
      <InputGroup transition="all 0.25s linear">
        {type === "tel" && <InputLeftAddon bg="transparent" children="+234" />}

        {/* The input element */}
        <Input
          fontSize={"15px"}
          w="full"
          name={name}
          type={type !== "password" ? type : show ? "text" : "password"}
          onChange={onChange}
          value={value}
          id={name}
          size="md"
          isInvalid={touched && errors}
          transition="all 0.25s linear"
          errorBorderColor="red.300"
          onBlur={onBlur}
          border="1px solid #EBF3F3"
          rounded={4}
          placeholder={placeholder}
          disabled={disabled}
          _placeholder={disabled && { color: "gray.900" }}
          bg={disabled && "#D8D8D8"}
          _focus={{
            border: "1px solid",
            backgroundColor: "transparent",
            borderColor: touched && errors ? "red.300" : "#05505288",
            // shadow: "sm",
          }}
          {...others}
        />

        {/* The all seeing password eye */}
        {type === "password" && !isLoading && (
          <InputRightElement>
            <IconButton size="sm" onClick={handleClick}>
              {show ? <FiEyeOff /> : <FiEye />}
            </IconButton>
          </InputRightElement>
        )}
        {isLoading && (
          <InputRightElement>
            <Spinner
              thickness="2px"
              speed="0.4s"
              emptyColor="gray.200"
              color="blue.500"
              size="md"
            />
          </InputRightElement>
        )}

        {type === "amount" && disabled && (
          <InputLeftElement>
            {/* <IconButton size="sm"> */}
            <FiLock color="#6C7777" />
            {/* </IconButton> */}
          </InputLeftElement>
        )}
      </InputGroup>

      {type === "amount" && (
        <Stack
          pos="absolute"
          bottom={-6}
          right={0}
          cursor="pointer"
          onClick={enableButton}
        >
          <Text color="#5C9EFC" fontSize="0.9rem">
            Click here to set the amount yourself
          </Text>
        </Stack>
      )}

      {/* The error text */}
      {touched && errors ? (
        <Stack pos="absolute" bottom={-6} right={0}>
          <Text color="red.300" fontSize="0.8rem">
            {errors}
          </Text>
        </Stack>
      ) : null}
    </Stack>
  );
}

export default CustomInput;
