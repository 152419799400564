import { Stack, IconButton } from "@chakra-ui/react";
import { FadeInPage } from "../../../../components";
import { BiArrowBack } from "react-icons/bi";

const DongleWrapper = ({ children, onClick }) => {
  return (
    <FadeInPage>
      <Stack maxW="650px" w="96%" mx="auto" spacing={4}>
        {onClick && (
          <IconButton w="max-content" variant="solid" onClick={onClick}>
            <BiArrowBack />
          </IconButton>
        )}
        <Stack rounded={5} spacing={6} bg="#fff" px={{ base: 4, md: 6 }} py={8}>
          {children}
        </Stack>
      </Stack>
    </FadeInPage>
  );
};

export default DongleWrapper;
