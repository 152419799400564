import React from "react";
import MapFull from "../../components/Map/Map";
import { FadeInPage } from "../../components";
import { Stack, AspectRatio } from "@chakra-ui/react";

function FullMap() {
  return (
    <FadeInPage>
      <Stack minH="95vh" w="full">
        <AspectRatio ratio={16 / 9} minH="500px">
          <MapFull />
        </AspectRatio>
      </Stack>
    </FadeInPage>
  );
}

export default FullMap;
