import React from "react";
import { Heading, Stack, HStack, Text } from "@chakra-ui/react";
import { StatsCard } from "../../components";
import sx from "./statscardwrapper.module.css";

function StatsCardWrapper({ stats }) {
  return (
    <Stack pb={2} color="fixbot">
      <Heading pb={1} fontSize="1.1rem">
        Vehicle Stats
      </Heading>
      <HStack
        justify="space-between"
        className={sx.stats_wrapper}
        w="100%"
        overflowX="auto"
        pb={2}
      >
        {stats
          ? stats.map((stat) => <StatsCard {...stat} key={stat.text} />)
          : Array(4)
              .fill(0)
              .map((_, i) => <StatsCard key={i} isLoaded={false} />)}
      </HStack>
    </Stack>
  );
}

export default StatsCardWrapper;
