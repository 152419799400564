import React from "react";
import {
  HStack,
  Stack,
  Text,
  Heading,
  Button,
  Divider,
} from "@chakra-ui/react";
import CustomModal from "../modal-wrapper";
import WarningAnim from "../../../animation/Warning";
import useResendMail from "../../../modules/hooks/auth/useResendMail";
import { MdDoneAll } from "react-icons/md";

function VerifyModal({ isOpen, onClose, handleVerify }) {
  const resendMail = useResendMail();

  const sendMail = () => resendMail.mutateAsync();
  const handleClose = () => {
    handleVerify();
    onClose();
  };
  return (
    <CustomModal
      backdropBlur="30px"
      //   backdropFilter="blur(10px) hue-rotate(90deg)"
      backdropFilter="blur(10px) saturate(1)"
      //   backdropFilter="blur(10px) "
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
    >
      <Stack pb={3} spacing={5}>
        <Stack spacing={6} alignItems="center" py={3}>
          <WarningAnim />
          <Stack alignItems="center" spacing={4} pb={4}>
            <Heading fontSize="26px">Unverified Account</Heading>
            <Divider />
            <Stack textAlign="center" spacing={4}>
              <Text fontSize="16px">
                Click on the verification mail sent to you to verify your
                account
              </Text>
              <HStack justifyContent="center" spacing={4}>
                <Button
                  colorScheme="whatsapp"
                  variant={resendMail.isSuccess ? "solid" : "primary"}
                  leftIcon={resendMail.isSuccess ? <MdDoneAll /> : null}
                  onClick={sendMail}
                  disabled={resendMail.isSuccess}
                  isLoading={resendMail.isLoading}
                >
                  {resendMail.isSuccess ? "Mail Sent" : "Resend Mail"}
                </Button>
                <Button variant="outline" onClick={onClose}>
                  Close
                </Button>
              </HStack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </CustomModal>
  );
}

export default VerifyModal;
