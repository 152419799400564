import React from "react";
import { useSocketContext } from "../../../lib/context/global/SocketContext";
import useLocalStore from "./useLocalStore";
import { USER } from "../../../constants";

function useGetGpsData() {
  const { socket, device } = useSocketContext();
  const localStore = useLocalStore();

  const [gps, setGps] = React.useState(
    localStore.getItem(USER.FIXBOT_USER_GPS)
  );
  const [gpsFirst, setGpsFirst] = React.useState(
    localStore.getItem(USER.FIXBOT_USER_GPS_FIRST)
  );

  const realGpsData = React.useMemo(() => gps, [gps]);

  React.useEffect(() => {
    if (!gps && device.length !== 0 && socket) {
      // socket.emit("monitorDevice", { dongleId: device[0].dongleId });
    }

    socket &&
      socket.on("gps", (res) => {
        setGps(res);
        if (!gpsFirst) {
          setGpsFirst(res);
          localStore.setItem(USER.FIXBOT_USER_GPS_FIRST, res);
        }
        localStore.setItem(USER.FIXBOT_USER_GPS, res);
      });
    return () => socket && socket.off("gps");
  }, []);

  return realGpsData;
}

export default useGetGpsData;

// base-url/carmd/identifyCar

// base-url/carmd/diagnoseFaults
