import useCustomMutation from "../../../lib/hooks/useCustomMutation";
import ENDPOINTS from "../../../constants";
import { storeUserData } from "../../../utils/utils";

function useAuthLogin() {
  return useCustomMutation({
    endpoint: ENDPOINTS.API_AUTH_LOGIN,
    method: "POST",
    onSettled: (response, err) => {
      if (!err) {
        // console.log(
        //   response?.data?.access_token,
        //   "response.data.access__token"
        // );
        storeUserData(response?.data);
      }
    },
    onError: (err) => {
      // console.log(err, "From useResetPassword hooks");
    },
  });
}

export default useAuthLogin;
