import useCustomMutation from "../../../lib/hooks/useCustomMutation";
import ENDPOINTS from "../../../constants";
import { useToast } from "@chakra-ui/react";

function useDeleteSchedule(id) {
  const toast = useToast();

  return useCustomMutation({
    endpoint: ENDPOINTS.API_DELETE_SCHEDULE(id),
    method: "delete",
    onSettled: (response, err) => {
      !err &&
        toast({
          title: `Successful`,
          description: `Event deleted successfully`,
          status: "success",
          variant: "left-accent",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
    },
    onError: (err) => {
      toast({
        title: `Update Failed`,
        description: `${err?.response?.data?.message}`,
        status: "error",
        variant: "left-accent",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
      // console.log(err, "from the useDeleteSchedule hook");
    },
  });
}

export default useDeleteSchedule;
