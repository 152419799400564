import React from "react";
import {
  Modal,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Divider,
  ModalBody,
} from "@chakra-ui/react";

function ModalWrapper({
  isOpen,
  onClose,
  children,
  header,
  backdropBlur,
  backdropFilter,
  ...others
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered {...others}>
      <ModalOverlay
        // backdropFilter='blur(10px) hue-rotate(90deg)'
        // backdropFilter="auto"
        // backdropInvert="80%"
        // backdropBlur="2px"
        backdropFilter={backdropFilter}
        backdropBlur={backdropBlur}
      />
      <ModalContent>
        {header && (
          <ModalHeader textAlign="center">
            <Text pb="2" pt={4}>
              {header}
            </Text>
            <Divider />
          </ModalHeader>
        )}
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ModalWrapper;
