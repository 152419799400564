import battery from "../assets/svg/battery.svg";
import fuel from "../assets/svg/fuel.svg";
import radiator from "../assets/svg/radiator.svg";
import thermometer from "../assets/svg/thermometer.svg";

const statsData = ({
  vehicleSpeed,
  fuelConsumptionVolume,
  coolantTemperature,
  batteryVoltage,
}) => [
  { img: radiator, title: "Speed", text: `${vehicleSpeed} Km/h` },
  { img: fuel, title: "Fuel consumption", text: `${fuelConsumptionVolume} L` },
  { img: thermometer, title: "Engine Temp", text: `${coolantTemperature}  °C` },
  { img: battery, title: "Battery Voltage", text: `${batteryVoltage} v` },
];

export default statsData;
