import React from "react";
import classes from "./ComingSoon.module.css";
import { FadeInPage } from "../../components";
import DashboardWrapper from "../DashboardWrapper/DashboardWrapper";
import woman from "../../assets/svg/woman.svg";
import { Image, Stack, Box, Heading } from "@chakra-ui/react";

function ComingSoon() {
  return (
    <FadeInPage>
      <DashboardWrapper noNotification isMdBgWhite>
        <Stack
          className={classes.routing}
          maxW="800px"
          w="100%"
          mt="50px"
          direction={{ base: "column" }}
          justifyContent="center"
          alignItems="center"
          p="50px 20px"
          spacing={8}
          sx={{ gap: "30px" }}
          bg={{ base: "inherit", md: "#fff" }}
        >
          <Heading as="h1" textAlign="center" fontSize="26px" mb="-60px">
            We're coming soon.
          </Heading>

          <Box p="20px">
            <Image src={woman} maxW="270px" alt="sjsjj" />
          </Box>
        </Stack>
      </DashboardWrapper>
    </FadeInPage>
  );
}

export default ComingSoon;
