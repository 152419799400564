import React from "react";
import {
  Image,
  HStack,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import useMenuRoutes from "../../lib/hooks/useMenuRoutes";
import { useNavigate } from "react-router-dom";
import { sidebarData } from "../../constants/sidebarData";

function MenuNav() {
  let navigate = useNavigate();
  let { activeRoute } = useMenuRoutes();

  return (
    <Menu>
      <MenuButton
        px={4}
        py={2}
        transition="all 0.2s"
        borderRadius="md"
        border="1px solid transparent"
        borderColor="#21365499"
        borderWidth="1px"
        fontSize="sm"
        bg="#fff"
        _focus={{ boxShadow: "outline" }}
      >
        <HStack>
          <Image
            borderRadius="full"
            src={activeRoute.currentLinkIcon}
            maxW="20px"
            alt="Fluffybuns the destroyer"
            mr="6px"
          />
          <span>
            {activeRoute.currentLinkName} <ChevronDownIcon />
          </span>
        </HStack>
      </MenuButton>
      <MenuList
        border="1px solid transparent"
        borderColor="#21365455"
        zIndex="9"
      >
        {sidebarData.map(({ title, path, Icon }, index) =>
          title !== "log out" ? (
            <React.Fragment key={title}>
              <MenuItem
                h={10}
                fontSize="sm"
                onClick={() => {
                  navigate(path);
                }}
              >
                <Image
                  borderRadius="full"
                  src={Icon}
                  maxW="20px"
                  alt="Fluffybuns the destroyer"
                  mr="12px"
                />
                <span>{title}</span>
              </MenuItem>
              <Divider
                borderColor="#21365455"
                display={index === sidebarData.length - 2 ? "none" : "block"}
              />
            </React.Fragment>
          ) : null
        )}
      </MenuList>
    </Menu>
  );
}

export default MenuNav;

// {sidebarData(path).map((path) => (
//     <MenuItem
//       key={path.title}
//       onClick={() =>
//         setActiveRoute((prevState) => ({
//           ...prevState,
//           ...path,
//         }))
//       }
//     >
//       <Link as={ReachLink} to={`${path.path}`}>
//         {path.path}
//       </Link>
//     </MenuItem>
//   ))}
