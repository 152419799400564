import React from "react";
import classes from "./Navbar.module.css";
import {
  Avatar,
  IconButton,
  HStack,
  Box,
  Menu,
  Stack,
  Divider,
  MenuList,
  MenuItem,
  MenuButton,
  Image,
} from "@chakra-ui/react";
import { ChevronDownIcon, BellIcon, HamburgerIcon } from "@chakra-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { getUserName } from "../../utils/utils";
import useGetProfile from "../../modules/hooks/account/useGetProfile";

function Navbar({ onOpen }) {
  const user = useGetProfile();

  return (
    <div className={classes.navbar}>
      <div className={classes.navbar__inner}>
        <Box
          display={{ base: "none", lg: "block" }}
          className={classes.navbar__menu}
        >
          {/* <IconButton
            size="sm"
            aria-label="notification"
            mr={12}
            ref={btnRef}
            onClick={collaspeNav}
            color="red"
          >
            <HamburgerIcon fontWeight="bolder" fontSize="16px" color="fixbot" />
          </IconButton> */}
        </Box>
        <Box ml="-20px" display={{ base: "block", lg: "none" }}>
          <Image
            h="30px"
            objectFit="cover"
            src="/Images/newlogo.png"
            alt="fixbot logo"
          />
        </Box>
        <HStack className={classes.navbar__icons}>
          <IconButton
            size="sm"
            border={0}
            rounded="full"
            aria-label="notification"
            mr={12}
            icon={<BellIcon />}
          />

          <HStack>
            <Menu>
              <MenuButton
                _hover={{ bg: "transparent" }}
                _active={{ bg: "transparent" }}
                borderColor="transparent"
                aria-label="Options"
                as={IconButton}
              >
                <Stack
                  px={1}
                  direction="row"
                  rounded="full"
                  alignItems="center"
                  cursor="pointer"
                >
                  <Avatar size="sm" name={getUserName(user)} />
                </Stack>
              </MenuButton>
              <MenuList>
                <MenuItem
                  fontSize="sm"
                  isDisabled
                  icon={<FontAwesomeIcon icon={faUser} />}
                >
                  {getUserName(user)}
                </MenuItem>
                <Divider />
                <MenuItem
                  fontSize="sm"
                  icon={<FontAwesomeIcon icon={faSignOutAlt} />}
                  onClick={onOpen}
                >
                  Log-Out
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </HStack>
      </div>
    </div>
  );
}

export default Navbar;
