import React from "react";
import { useSocketContext } from "../../../lib/context/global/SocketContext";
import useLocalStore from "./useLocalStore";
import { USER } from "../../../constants";

function useGetCanbusData() {
  const { socket, device } = useSocketContext();
  const localStore = useLocalStore();

  const [canbus, setCanbus] = React.useState(
    localStore.getItem(USER.FIXBOT_USER_CANBUS)
  );

  React.useEffect(() => {
    if (device.length !== 0 && socket) {
      // socket.emit("monitorDevice", { dongleId: device[0].dongleId });
      socket.on("canbus", (res) => {
        setCanbus(res);
        localStore.setItem(USER.FIXBOT_USER_CANBUS, res);
      });
    }

    return () => {
      if (socket) {
        socket.off("canbus");
      }
    };
  }, []);

  return canbus;
}

export default useGetCanbusData;
