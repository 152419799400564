import React from "react";
import { Stack, Select, Text, Spinner, FormLabel } from "@chakra-ui/react";
import { AiOutlineCaretDown } from "react-icons/ai";

function CustomSelect({
  value,
  onChange,
  name,
  type,
  onBlur,
  label,
  options,
  touched,
  disabled,
  errors,
  placeholder,
  isLoading = false,
  ...others
}) {
  return (
    <Stack w="full" pos="relative" alignItems="start">
      {/* The labe */}
      <FormLabel
        textAlign="left"
        fontWeight="bold"
        as="label"
        htmlFor={name}
        mb={-1}
        color="#68768B"
        fontSize="sm"
        textTransform="capitalize"
      >
        {label}
      </FormLabel>

      {/* The input element */}
      <Select
        icon={
          isLoading ? (
            <Spinner
              thickness="2px"
              speed="0.4s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          ) : (
            <AiOutlineCaretDown fontSize="16px" />
          )
        }
        fontSize={"15px"}
        w="full"
        name={name}
        onChange={onChange}
        value={value}
        id={name}
        size="md"
        isInvalid={touched && errors}
        transition="all 0.25s linear"
        errorBorderColor="red.300"
        onBlur={onBlur}
        border="1px solid #EBF3F3"
        rounded={4}
        placeholder={placeholder}
        disabled={disabled || isLoading}
        _placeholder={disabled && { color: "gray.900" }}
        bg={disabled && "#D8D8D8"}
        _focus={{
          border: "1px solid",
          backgroundColor: "transparent",
          borderColor: touched && errors ? "red.300" : "#05505288",
          // shadow: "sm",
        }}
        {...others}
      >
        {options &&
          options.map((item) => (
            <option value={item.value} key={item.value}>
              {item.name}
            </option>
          ))}
      </Select>

      {/* The error text */}
      {touched && errors ? (
        <Stack pos="absolute" bottom={-6} left={0}>
          <Text color="red.300" fontSize="0.8rem">
            {errors}
          </Text>
        </Stack>
      ) : null}
    </Stack>
  );
}

export default CustomSelect;
