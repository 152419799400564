import React from "react";
import { Text, Stack, Heading } from "@chakra-ui/react";
import useAuthLogin from "../../hooks/auth/useAuthLogin";
import { AuthWrapper } from "../../../containers";
import { Input, Button, IfElse } from "../../../components";
import { Link } from "react-router-dom";
import useValidateAuth from "../../hooks/auth/useValidateAuthFields";
import useErrorHandler from "../../../lib/hooks/useErrorHandler";
import { PAGES } from "../../../constants";
import Welcome from "./Welcome";

const Login = () => {
  const authLogin = useAuthLogin();
  useErrorHandler(authLogin, "Login Successful");

  const handleLogin = (values) => {
    authLogin.mutateAsync(values);
  };

  const formik = useValidateAuth(
    {
      username: "",
      password: "",
    },
    handleLogin
  );

  return (
    <AuthWrapper isVerify={authLogin.isSuccess && authLogin.data} isLogin>
      <IfElse
        ifOn={!(authLogin.isSuccess && authLogin.data)}
        ifOnElse={authLogin.isSuccess && authLogin.data}
        onElse={<Welcome message="Welcome back " to={PAGES.DASHBOARD_PAGE} />}
      >
        <Stack
          maxW="550px"
          w="96%"
          margin="auto"
          alignItems="stretch"
          spacing={8}
          as="form"
          onSubmit={formik.handleSubmit}
        >
          <Heading
            as="h2"
            fontSize={{ base: "1.4rem", md: "1.8rem" }}
            textAlign="center"
          >
            Sign In to Fixbot
          </Heading>
          <Stack bg="#fff" px={{ base: 4, md: 8 }} py={8} spacing={3}>
            <Input
              name="username"
              placeholder="Enter your email"
              type="email"
              {...formik.getFieldProps("username")}
              touched={formik.touched.username}
              errors={formik.errors.username}
            />
            <Input
              name="password"
              placeholder="Enter your password"
              type="password"
              // label="Password"
              {...formik.getFieldProps("password")}
              touched={formik.touched.password}
              errors={formik.errors.password}
            />

            <Stack>
              <Button
                mt={8}
                py={6}
                type="submit"
                isLoading={authLogin.isLoading && !authLogin.error}
              >
                Sign in
              </Button>
            </Stack>
          </Stack>
          <Stack alignItems="center" mt={4}>
            <Text textAlign="center" fontSize="1rem">
              <Link to="/forgot-password">
                <Text as="strong" color="fixbot">
                  Forgot Password? {"  "}
                </Text>
              </Link>
              Doesn’t have an account? {"  "}
              <Link to="/signup">
                <Text as="strong" color="fixbot">
                  Sign up now
                </Text>
              </Link>
            </Text>
          </Stack>
        </Stack>
      </IfElse>
    </AuthWrapper>
  );
};

export default Login;
