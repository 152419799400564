import React from "react";
import { Box, Text } from "@chakra-ui/react";

function IssueItem({ text }) {
  return (
    <Box
      w="full"
      bg={{ base: "white", md: "issueBg" }}
      py="6px"
      px="15px"
      mb="5px"
    >
      <Text fontSize="sm" color="fixbotText1">
        {text}
      </Text>
    </Box>
  );
}
export default IssueItem;
