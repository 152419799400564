import useCustomMutation from "../../../lib/hooks/useCustomMutation";
import ENDPOINTS from "../../../constants";
import { useToast } from "@chakra-ui/react";

function useAddDevice() {
  const toast = useToast();
  return useCustomMutation({
    endpoint: ENDPOINTS.API_ADD_DEVICES,
    method: "POST",
    onSettled: (response, err) => {
      !err &&
        toast({
          title: `Successful`,
          description: `Dongle Added`,
          status: "success",
          position: "top-right",
          duration: 3000,
          isClosable: true,
        });
    },
    onError: (err) => {
      toast({
        title: `Update Failed`,
        description: `${err?.response?.data?.message}`,
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
      // console.log(err, "From useResetPassword hooks");
    },
  });
}

export default useAddDevice;
