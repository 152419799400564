import React from "react";
import classes from "./TimeLine.module.css";
import { Spinner, Box, Text, HStack, Stack, Icon } from "@chakra-ui/react";
import { MdOutlineMyLocation, MdLocationOn } from "react-icons/md";
import useGetGpsData from "../../modules/hooks/socket/useGetGpsData";
import useLocalStore from "../../modules/hooks/socket/useLocalStore";
import { toTimeString } from "../../utils/utils";
import { USER } from "../../constants";

function TimeLine({ start = "", end = "", isLoading }) {
  const gpsData = useGetGpsData();
  const localStore = useLocalStore();

  return (
    <Box
      className={classes.timeline__wrapper}
      px="20px"
      py="15px"
      pb="10px"
      bg="#213654"
      minH="200px"
      borderRadius={8}
    >
      {/* <div className={classes.timeline__wrapperSecondCircle} /> */}
      {/* <div className={classes.timeline__wrapperCircle} /> */}

      <Text fontSize="md" as="strong" color="white">
        Car Location
      </Text>
      <Stack
        p={1}
        bg="#ebf3f3"
        position="absolute"
        top="42px"
        left={6}
        rounded="full"
        zIndex="99"
      >
        <Icon as={MdOutlineMyLocation} color="fixbot" />
      </Stack>
      <Stack
        p={1}
        bg="#ebf3f3"
        position="absolute"
        top="42px"
        right={6}
        rounded="full"
        zIndex="99"
      >
        <Icon as={MdLocationOn} color="fixbot" />
      </Stack>

      <HStack
        alignItems="flex-end"
        pt={6}
        h={{ base: "full", md: "75%" }}
        spacing={10}
        className={classes.timeline}
      >
        <Box>
          {gpsData && (
            <>
              <Text fontSize="xs" color="#fff">
                Start
              </Text>
              {/* <Text fontSize="sm" color="#fff">
                N/A
              </Text> */}
              <Text fontSize="sm" color="#fff">
                {start}
              </Text>
            </>
          )}
          {!gpsData && isLoading && (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          )}
          {!isLoading && !gpsData && (
            <Stack
              w="full"
              rounded={8}
              mb={2}
              justifyContent={"center"}
              p={4}
              // textAlign="center"
            >
              <Text color="#fff">
                <strong>N/A</strong>
              </Text>
            </Stack>
          )}
        </Box>

        <Box textAlign="right">
          {gpsData && (
            <>
              <Text fontSize="xs" color="#fff">
                End
              </Text>
              <Text fontSize="sm" color="#fff">
                {toTimeString(gpsData.createdAt)}
              </Text>
              <Text fontSize="sm" color="#fff">
                {end}
              </Text>
            </>
          )}
          {!gpsData && isLoading && (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          )}
          {!isLoading && !gpsData && (
            <Stack
              w="full"
              rounded={8}
              mb={2}
              justifyContent={"center"}
              p={4}
              // textAlign="center"
            >
              <Text color="#fff">
                <strong>N/A</strong>
              </Text>
            </Stack>
          )}
        </Box>
      </HStack>
    </Box>
  );
}

export default TimeLine;
