import React from "react";
import classes from "./UserCard.module.css";
import {
  Button,
  Box,
  HStack,
  Avatar,
  VStack,
  Text,
  Badge,
  Divider,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import Ratings from "../../Ratings/Ratings";
import {
  getUserData,
  getUserName,
  toDateString,
  storeUserData,
} from "../../../utils/utils";
import useGetProfile from "../../../modules/hooks/account/useGetProfile";
// import moment from "moment";
import useGetDevices from "../../../modules/hooks/devices/useGetDevices";

function UserCard() {
  const { isVerified, token } = getUserData();
  const userDetails = useGetProfile();
  const openVerify = () => {
    storeUserData({ isVerified: false, token });
  };

  const dongleDevices = useGetDevices();
  const successBadge = (
    <Badge
      py={1}
      px={4}
      textAlign="center"
      rounded={5}
      variant="solid"
      bg={"#00A344"}
    >
      <Text fontSize="0.8rem" textTransform="capitalize" color="#fff">
        Active
      </Text>
    </Badge>
  );

  const unVerifiedBadge = (
    <Button
      py={1}
      px={4}
      textAlign="center"
      rounded={5}
      variant="solid"
      onClick={openVerify}
      bg={"red.600"}
    >
      <Text fontSize="0.8rem" textTransform="capitalize" color="#fff">
        Unverified
      </Text>
    </Button>
  );
  return (
    <Box w="full" maxW="400px" mb={4} className={classes.usercard}>
      <div className={classes.usercard__circle} />
      <div className={classes.usercard__secondCircle} />
      <Box className={classes.usercard__inner}>
        <HStack alignItems="flex-start" justify="space-between" mb={3}>
          <HStack mb={4}>
            <Avatar
              name={getUserName(userDetails)}
              mr={2}
              // src="https://bit.ly/dan-abramov"
            />
            <VStack align="flex-start" justify="center">
              <Text fontSize="sm" as="strong" color="white" mb={-2}>
                {getUserName(userDetails)}
              </Text>
              <Text fontSize="0.8rem" color="gray.300">
                #
                {dongleDevices?.value && dongleDevices?.value.length !== 0
                  ? dongleDevices?.value[0].dongleId
                  : "****"}
              </Text>
            </VStack>
          </HStack>

          {/* <IconButton variant="outline" border={0} size="sm"> */}
          <ChevronDownIcon fontSize="24px" />
          {/* </IconButton> */}
        </HStack>

        <HStack justify="space-between" mb={3}>
          <Text fontSize="sm" color="#fff">
            Driver's Rating
          </Text>
          <Ratings />
        </HStack>

        <Divider mb={3} />

        <HStack justify="space-between" alignItems="center">
          <Text fontSize="sm" color="#fff">
            {toDateString(new Date())}
          </Text>
          {isVerified ? successBadge : unVerifiedBadge}
        </HStack>
      </Box>
    </Box>
  );
}

export default UserCard;
