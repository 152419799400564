import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import json from "./json/88860-success-animation.json";

const SuccessAnim = () => {
  return (
    <Player
      autoplay
      loop
      src={json}
      style={{ height: "200px", width: "250px" }}
    ></Player>
  );
};

export default SuccessAnim;
