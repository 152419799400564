import React from "react";
import { HStack } from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";

function Ratings() {
  return (
    <HStack spacing={1}>
      <StarIcon boxSize={4} />
      <StarIcon boxSize={4} />
      <StarIcon boxSize={4} />
      <StarIcon boxSize={4} color="gray" />
      <StarIcon boxSize={4} color="gray" />
    </HStack>
  );
}

export default Ratings;
