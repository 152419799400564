import { useState } from 'react';
import { Link } from "react-router-dom";
import { Box, Text, Heading, Divider, Button } from '@chakra-ui/react/';
import   FadeInPage  from "../FadeInPage/FadeInPage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import toast, { Toaster } from 'react-hot-toast';
import { useMediaQuery } from 'react-responsive';


const MechanicalFaults = ({ faults }) =>{
    const [count, setCount] = useState(3);
    const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

    const seeMore = () => {   
        if (Object.keys(faults).length > 3) setCount(count + 3);
        if (
          Object.keys(faults).length ===
          Object.keys(faults).slice(0, count).length
        )
          return toast.error("No more faults");
    }

    return (
      faults &&
      Object.keys(faults).length > 0 &&(
        <FadeInPage w="100%">
          <Toaster position={isMobile ? "top-center" : "top-right"} />
          <Box
            bg="#fff"
            borderRadius="14px"
            p={{
              sm: "1rem",
              md: "1rem",
              lg: "1.5rem",
              xl: "1.5rem",
              base: "1rem",
            }}
          >
            <Heading as="h3" size="xl">
              <Text
                fontSize={{
                  sm: "1.2rem",
                  md: "1.2rem",
                  lg: "1.8rem",
                  xl: "1.8rem",
                  base: "1.2rem",
                }}
                color="#213654"
                fontWeight="600"
              >
                {Object.keys(faults).length} faults detected
              </Text>
            </Heading>
            <Box mt="1rem">
              {Object.keys(faults)
                .slice(0, count)
                .map((item, i) => (
                  <Link to="/my/diagnostics/faults" key={i}>
                    <Box mt="1.5rem" _hover={{ opacity: "0.7" }}>
                      <Heading as="h5" size="sm">
                        <Text
                          fontSize={{
                            sm: "1rem",
                            md: "1rem",
                            lg: "1.2rem",
                            xl: "1.2rem",
                            base: "1rem",
                          }}
                          color="#68768B"
                          fontWeight="500"
                        >
                          {faults[item].carmd.desc}
                        </Text>
                      </Heading>
                      <Heading as="h5" size="sm" mt=".2rem">
                        <Text
                          fontSize={{
                            sm: "1rem",
                            md: "1rem",
                            lg: "1.2rem",
                            xl: "1.2rem",
                            base: "1rem",
                          }}
                          color="#68768B"
                          fontWeight="500"
                        >
                          {faults[item].carmd.code}
                        </Text>
                      </Heading>
                      <Divider
                        border="0"
                        height="0"
                        borderTop="1px solid #68768B"
                        borderBottom="0"
                        mt="0.7rem"
                      />
                    </Box>
                  </Link>
                ))}
            </Box>
            {Object.keys(faults).length !==
              Object.keys(faults).slice(0, count).length && (
              <Box
                mt="1rem"
                d="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Button border="none" onClick={seeMore}>
                  <Heading as="h5" size="sm" mt=".2rem">
                    <Text
                      fontSize={{
                        sm: "1rem",
                        md: "1rem",
                        lg: "1.2rem",
                        xl: "1.2rem",
                        base: "1rem",
                      }}
                      color="#68768B"
                      fontWeight="500"
                    >
                      {" "}
                      See more <FontAwesomeIcon icon={faChevronRight} />
                    </Text>
                  </Heading>
                </Button>
              </Box>
            )}
          </Box>
        </FadeInPage>
      )
    );}


export default MechanicalFaults;