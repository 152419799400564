function useLocalStore(storeId) {
  const getItem = (storeId) => {
    const info = window.sessionStorage.getItem(storeId);
    return info ? JSON.parse(info) : null;
  };

  const setItem = (storeId, storeData) => {
    window.sessionStorage.setItem(storeId, JSON.stringify(storeData));
  };

  return { getItem, setItem };
}

export default useLocalStore;
