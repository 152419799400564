import { useFormik } from "formik";

function useValidateLogin(initialValues, callBack) {
  const isNotUndefined = (field) => initialValues[field] !== undefined;

  const validate = (values) => {
    const errors = {};

    if (isNotUndefined("email")) {
      if (!values.email) {
        errors.email = "Required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
    }

    if (isNotUndefined("task")) {
      if (!values.task) {
        errors.task = "Input task";
      } else if (values.task.length < 4) {
        errors.task = "Incomplete Task";
      }
    }

    if (isNotUndefined("endDateTime")) {
      if (!values.endDateTime) {
        errors.endDateTime = "No End time";
      }
    }
    if (isNotUndefined("startDateTime")) {
      if (!values.startDateTime) {
        errors.startDateTime = "No Start time";
      }
    }

    if (isNotUndefined("freq")) {
      if (!values.freq) {
        errors.freq = "Select frequency";
      }
    }

    if (isNotUndefined("phoneNumber")) {
      if (!values.phoneNumber) {
        errors.phoneNumber = "Required";
      } else if (
        /[a-z]/gi.test(values.phoneNumber) ||
        values.phoneNumber.length !== 11
      ) {
        errors.phoneNumber = "Invalid phone number";
      }
    }

    if (isNotUndefined("firstname")) {
      if (!values.firstname) {
        errors.firstname = "Required";
      } else if (
        values.firstname.length < 3 ||
        /[0-9]/gi.test(values.firstname)
      ) {
        errors.firstname = "Must be 3 characters or more";
      }
    }

    if (isNotUndefined("lastname")) {
      if (!values.lastname) {
        errors.lastname = "Required";
      } else if (
        values.lastname.length < 3 ||
        /[0-9]/gi.test(values.lastname)
      ) {
        errors.lastname = "Must be 3 characters or more";
      }
    }

    if (isNotUndefined("password")) {
      if (!values.password) {
        errors.password = "Required";
      } else if (values.password.length < 7) {
        errors.password = "Must be 7 characters or more";
      }
    }

    if (isNotUndefined("bvn")) {
      if (!values.bvn) {
        errors.bvn = "Required";
      } else if (
        /[a-z]/gi.test(values.phoneNumber) ||
        values.phoneNumber.length !== 11
      ) {
        errors.bvn = "Must be 11 digit";
      }
    }

    if (isNotUndefined("newPassword")) {
      if (!values.newPassword) {
        errors.newPassword = "Required";
      } else if (values.newPassword.length < 6) {
        errors.newPassword = "Must be 6 characters or more";
      }
    }

    if (isNotUndefined("confirmPassword")) {
      if (!values.confirmPassword) {
        errors.confirmPassword = "Required";
      } else if (values.confirmPassword !== values.newPassword) {
        errors.confirmPassword = "Pin must be identical";
      }
    }
    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (values) => {
      //console.log(JSON.stringify(values, null, 2));
      callBack && callBack(values);
    },
  });

  return formik;
}

export default useValidateLogin;
