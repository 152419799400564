import { extendTheme } from "@chakra-ui/react";
import Button from "./button";

const newTheme = extendTheme({
  colors: {
    fixbot: "#213654",
    fixbotBg: "#E5E5E5",
    fixbotGray: "#B5AFAF",
    fixbotDanger: "#E32424",
    fixbotText1: "#3A3A3A",
    fixbotText2: "#C4C4C4",
    fixbotGreen: "#91D738",
    issueBg: "#EBF3F3",
  },
  fonts: {
    // body: "Barlow, sans-serif",
    // body: "Titillium Web, sans-serif",
    // body: "Poppins, sans-serif",
    // heading: "Unica One, cursive",
    // heading: "Vollkorn, serif;",
    // heading: "Titillium Web, sans-serif",

    // body: "Nunito",
    // heading: "Nunito",

    mono: "Menlo, monospace",
  },
  components: {
    Button,
  },
});

export default newTheme;
