import useCustomMutation from "../../../lib/hooks/useCustomMutation";
import ENDPOINTS from "../../../constants";

function useForgotPassword() {
  return useCustomMutation({
    endpoint: ENDPOINTS.API_AUTH_FORGOT_PASSWORD,
    method: "POST",
    onError: (err) => {
      // console.log(err, "From useResetPassword hooks");
    },
  });
}

export default useForgotPassword;
