import useCustomMutation from "../../../lib/hooks/useCustomMutation";
import ENDPOINTS from "../../../constants";
import { useToast } from "@chakra-ui/react";

function useResendMail() {
  const toast = useToast();
  return useCustomMutation({
    endpoint: ENDPOINTS.API_AUTH_RESEND_MAIL,
    method: "POST",
    onSettled: (response, err) => {
      !err &&
        toast({
          title: `Successful`,
          description: "Mail sent",
          status: "success",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      // console.log(response, "from the useEditProfile hook");
    },
    onError: (err) => {
      // console.log(err, "From useResendMail hooks");
    },
  });
}

export default useResendMail;
