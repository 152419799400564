import { useLocation } from "react-router-dom";
import { isExpired, decodeToken } from "react-jwt";

function useGetToken() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get("token");

  return {
    token,
    isTokenExpired: isExpired(token),
    tokenData: decodeToken(token),
  };
}

export default useGetToken;
