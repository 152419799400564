import React from "react";
import { DashboardWrapper } from "../../../containers";
import { FadeInPage } from "../../../components";
import { Spinner, Stack, Text, Heading, Badge } from "@chakra-ui/react";
import useGetDriverData from "../../hooks/socket/useGetDriverData";
import driverDataFxn from "../../../constants/driverData";

const DataRow = ({ isBad = false, action, value, parameter, ...props }) => (
  <Stack borderBottom="1px solid rgba(0,0,0,0.1)">
    <Stack
      alignItems="center"
      color="#3A3A3A"
      direction="row"
      maxW="820px"
      w="full"
      mx="auto"
      py={2}
      px={3}
      justifyContent="space-between"
      {...props}
    >
      <Text fontSize="sm" w="33.3%">
        {parameter}
      </Text>
      <Text fontSize="sm" w="33.3%" textAlign="center">
        {action}
      </Text>
      {/* <Stack w="25%" alignItems="center">
        <Badge
          py={1}
          w="100px"
          px={4}
          textAlign="center"
          rounded={5}
          variant="solid"
          bg={isBad ? "#E32424" : "#00A344"}
        >
          <Text fontSize="0.8rem" textTransform="capitalize" color="#fff">
            {isBad ? "Not Normal" : "Normal"}
          </Text>
        </Badge>
      </Stack> */}
      <Text fontSize="sm" w="33.3%" textAlign="right">
        {/* 1852.3 times */}
        {value}
      </Text>
    </Stack>
  </Stack>
);

function DriverBehavior() {
  const [isLoading, setLoading] = React.useState(true);
  const driverData = useGetDriverData();

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  let body = (
    <Stack w="full" h="100px" alignItems="center" justifyContent="center">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Stack>
  );

  if (driverData) {
    body = driverDataFxn(driverData).map((item) => (
      <DataRow key={item.action} {...item} />
    ));
  }

  if (!driverData && !isLoading) {
    body = (
      <Stack w="full" bg="#fff" p={4} py={10} textAlign="center">
        <Text fontSize="1.2rem" color="fixbot">
          No data from dongle <strong>OR</strong> No dongle connected to this
          account
        </Text>
      </Stack>
    );
  }
  return (
    <FadeInPage>
      <DashboardWrapper
        noNotification
        headerComp={
          <Stack spacing={2}>
            <Heading fontSize="2rem">Driver’s Behaviour Data</Heading>
            <Text>Avoid breakdowns, detect your car faults on time.</Text>
          </Stack>
        }
      >
        <Stack
          mt={10}
          border="1px solid rgba(0,0,0,0.08)"
          rounded={7}
          overflowX="hidden"
          spacing={0}
        >
          {driverData && (
            <Stack
              bg="#EAF3FF"
              // bg="pink"
              w="full"
            >
              <Stack
                color="#3A3A3A"
                direction="row"
                maxW="820px"
                w="full"
                mx="auto"
                py={4}
                px={3}
                justifyContent="space-between"
              >
                <Text fontSize="1.1rem" w="33.3%">
                  Parameters
                </Text>
                <Text fontSize="1.1rem" w="33.3%" textAlign="center">
                  Action
                </Text>
                {/* <Text fontSize="1.1rem" textAlign="center" w="33.3%">
                Level
              </Text> */}
                <Text fontSize="1.1rem" w="33.3%" textAlign="right">
                  Values
                </Text>
              </Stack>
            </Stack>
          )}
          <Stack bg="#fff">
            {body}
            {/* <DataRow pb={4} /> */}
            <Stack pb={4} />
          </Stack>
        </Stack>
      </DashboardWrapper>
    </FadeInPage>
  );
}

export default DriverBehavior;
