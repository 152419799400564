import React from "react";
import { Stack, Text, Heading } from "@chakra-ui/react";
import CustomModal from "../modal-wrapper";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../../constants";
import SuccessAnim from "../../../animation/Success";

function SignUpModal({ isOpen, onClose }) {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        navigate(PAGES.LOGIN_PAGE);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <CustomModal
      backdropBlur="2px"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <Stack pb={3} spacing={5}>
        <Stack spacing={6} alignItems="center" py={3}>
          <SuccessAnim />
          <Stack alignItems="center" spacing={4} pb={4} pt={3}>
            <Heading fontSize="26px">Signup Successful</Heading>
            <Text fontSize="16px">Redirecting to Login...</Text>
          </Stack>
        </Stack>
      </Stack>
    </CustomModal>
  );
}

export default SignUpModal;
