import React from "react";
import { Button, Text } from "@chakra-ui/react";

function SettingBtn({ children, active, onClick }) {
  return (
    <Button
      size="md"
      px="20px"
      bg="white"
      color="fixbotText1"
      w="full"
      onClick={onClick}
      justifyContent="center"
      border={0}
      rounded={4}
      borderBottom="4px solid transparent"
      borderBottomColor={active ? "#213654" : "transparent"}
      maxW="250px"
      minW="180px"
      _active={{ shadow: "0 0 0 0px #21365444" }}
      _focus={{ shadow: "0 0 0 0px #21365444" }}
      _hover={{ bg: "#fff" }}
    >
      <Text fontSize="sm">{children}</Text>
    </Button>
  );
}

export default SettingBtn;
