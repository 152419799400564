import { useMutation } from "react-query";
import { secureRequestHandler } from "./useCustomQuery";
import { useToast } from "@chakra-ui/react";

const getMutationAction = (mutationData) => {
  const { endpoint, method, headers } = mutationData;
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_STAGING_API_URL
      : process.env.REACT_APP_PRODUCTION_API_URL;
  const url = baseUrl + endpoint;

  return {
    mutationFn: (body) =>
      secureRequestHandler({
        url,
        method,
        body,
        headers,
      }),
    ...mutationData,
  };
};

function useCustomMutation(mutationData) {
  const { mutationFn, endpoint, then, onSuccess, ...others } =
    getMutationAction(mutationData);
  const toast = useToast();

  const mutatationResult = useMutation(mutationFn, {
    mutationKey: endpoint,
    onError: (err) => {
      toast({
        title: `${err?.response?.statusText}`,
        description: `${err.response?.data?.message}`,
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...others,
  });

  return { ...mutatationResult, value: mutatationResult?.data?.data };
}

export default useCustomMutation;
