import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "./css/style.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Dashboard from "./pages/Dashboard/Dashboard";
import LoginPage from "./pages/login";
import SignUpPage from "./pages/signup";
import ForgotPasswordPage from "./pages/forgot-password";
import ResetPasswordPage from "./pages/reset-password";

import VerifyPage from "./pages/verify";
// Error404 Page
import NotFound from "./components/Notfound/Notfound";
// Rotes Configuration
import routes from "./routes";
// Protected Routes Component handler
import ProtectedPages from "./modules/protected-page";
// Authorized Routes Component handler
import AuthorizedPages from "./modules/authorized-page";

import VerifyAccount from "./modules/auth/verify-email";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";

library.add(fab, faCheckSquare, faCoffee);

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<ProtectedPages />}> */}
        <Route index path="/" element={<LoginPage />} />
        <Route path="signup" element={<SignUpPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="verify" element={<VerifyPage />} />
        {/* </Route> */}
        {/* Email Verification */}
        <Route path="forgot-password" element={<ForgotPasswordPage />} />
        <Route path="reset-password" element={<ResetPasswordPage />} />

        <Route path="/email-verification" element={<VerifyAccount />} />
        {/* Dashboard Routes with their wrapper */}
        <Route element={<AuthorizedPages />}>
          <Route path="my" element={<Dashboard />}>
            {routes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
          </Route>
        </Route>
        {/* Error 404 route */}
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
