const ifNull = (par, unit = "") => (par ? `${par} ${unit}` : "N/A");

const driverData = ({
  averageSpeed,
  totalHarshAccelerationNo,
  totalIdleTime,
  totalIgnitionNo,
  totalDrivingTime,
  totalHarshBrakeNo,
  historyHighestSpeed,
  historyHighestRotation,
}) => [
  {
    parameter: "Ignition",
    action: "Total number of ignition",
    value: ifNull(totalIgnitionNo, "times"),
  },
  {
    parameter: "Driving time",
    action: "Total driving time",
    value: ifNull(totalDrivingTime, "hrs"),
  },
  {
    parameter: "Idle time",
    action: "Total Idle time",
    value: ifNull(totalIdleTime, "hrs"),
  },
  {
    parameter: "Hot Start",
    action: "Number of hot starts",
    value: ifNull(null),
  },
  {
    parameter: "Speed history",
    action: "Highest speed",
    value: ifNull(historyHighestSpeed, "km/hr"),
  },
  {
    parameter: "Average Speed",
    action: "Average Speed",
    value: ifNull(averageSpeed, "km/hr"),
  },
  {
    parameter: "Rotation",
    action: "Engine rotation",
    value: ifNull(historyHighestRotation, "rpm"),
  },
  {
    parameter: "Harsh acceleration",
    action: "Total number of harsh acceleration",
    value: ifNull(totalHarshAccelerationNo, "times"),
  },
  {
    parameter: "Harsh braking",
    action: "Total number of harsh brake ",
    value: ifNull(totalHarshBrakeNo, "times"),
  },
];

export default driverData;
