import { darken, lighten } from "@chakra-ui/theme-tools";
import { theme } from "@chakra-ui/react";

const Button = {
  // style object for base or default style
  baseStyle: {
    fontWeight: "normal",
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    primary: {
      ...theme.components.Button.variants.outline,
      bg: "fixbot",
      color: "white",
      _disabled: {
        opacity: 0.5,
        background: "fixbot",
        cursor: "not-allowed",
        boxShadow: "none",
      },
    },

    success: {
      ...theme.components.Button.variants.outline,
      bg: "fixbotGreen",
      color: "white",
      _hover: {
        bg: darken("fixbotGreen", 10),
      },
      _disabled: {
        opacity: 0.5,
        background: "fixbotGreen",
        cursor: "not-allowed",
        boxShadow: "none",
      },
    },

    secondary: {
      ...theme.components.Button.variants.outline,
      bg: "fixbotText2",
      color: "white",
      _hover: {
        bg: darken("fixbotText2", 10),
      },
      _disabled: {
        opacity: 0.5,
        background: "fixbotText2",
        cursor: "not-allowed",
        boxShadow: "none",
      },
    },

    danger: {
      ...theme.components.Button.variants.outline,
      bg: "red.600",
      color: "white",
      _hover: {
        bg: darken("red.600", 10),
      },
    },
  },
  // default values for `size` and `variant`
  defaultProps: {
    size: "md",
    variant: "outline",
    spinnerPlacement: "end",
  },
};

export default Button;
