import React from "react";
import classes from "./schedule.module.css";
import { FadeInPage } from "../../../components";
import { DashboardWrapper, ScheduleDrawer } from "../../../containers";
import woman from "../../../assets/svg/woman.svg";
import caution from "../../../assets/svg/caution.svg";
import {
  Button,
  Image,
  HStack,
  Box,
  Text,
  Heading,
  Spinner,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { PlusSquareIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import useCreateSchedule from "../../hooks/schedule/useCreateSchedule";
import useGetSchedule from "../../hooks/schedule/useGetSchedule";
import { rrulestr } from "rrule";

const localizer = momentLocalizer(moment);

function Schedule() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [event, setEvent] = React.useState([]);
  const createSchedule = useCreateSchedule();
  const getSchedule = useGetSchedule();
  const [status, setStatus] = React.useState("add");

  React.useEffect(() => {
    const givenEvents = [];
    if (
      getSchedule?.value &&
      getSchedule.value?.maintenanceSchedules?.length !== 0
    ) {
      getSchedule.value.maintenanceSchedules.forEach(({ event, rrules }) =>
        rrulestr(rrules)
          .all()
          .forEach((day) => {
            givenEvents.push({
              start: day,
              end: moment(day).add(30, "m").toDate(),
              title: event,
            });
          })
      );
    }
    if (givenEvents.length > 0) {
      setEvent(givenEvents);
    }
  }, [getSchedule.value]);

  return (
    <FadeInPage>
      <DashboardWrapper
        noNotification
        isMdBgWhite
        headerComp={
          <Stack spacing={2}>
            <Heading fontSize="2rem">Maintenance Schedule</Heading>
            <Text>
              Schedule car Todos and keep your car maintenance on track.
            </Text>
          </Stack>
        }
      >
        {getSchedule.isLoading ? (
          <div className={classes.schedule}>
            <Stack className={classes.schedule__create} alignItems="center">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Stack>
          </div>
        ) : (
          <>
            {event.length === 0 ||
            getSchedule.value?.maintenanceSchedules?.length === 0 ? (
              <div className={classes.schedule}>
                <Stack className={classes.schedule__create} alignItems="center">
                  <HStack spacing={4}>
                    <Box mb={-3}>
                      <Image src={caution} w="30px" />
                    </Box>
                    <Text
                      color="red.600"
                      fontWeight="bold"
                      textAlign={{ base: "center", md: "left" }}
                    >
                      You have no existing schedule
                    </Text>
                  </HStack>

                  <Image src={woman} w="full" maxW="300px" alt="sjsjj" />
                  <HStack justify={{ base: "center", md: "flex-end" }}>
                    <Button
                      leftIcon={<PlusSquareIcon />}
                      rightIcon={<ChevronRightIcon />}
                      variant="primary"
                      size="md"
                      px={10}
                      onClick={() => {
                        setStatus("add");
                        onOpen();
                      }}
                      color="#fff"
                    >
                      Create New
                    </Button>
                  </HStack>
                </Stack>
              </div>
            ) : (
              <Stack spacing={3}>
                <HStack justifyContent="space-between">
                  <Button
                    leftIcon={<PlusSquareIcon />}
                    variant="primary"
                    size="md"
                    px={10}
                    onClick={() => {
                      setStatus("add");
                      onOpen();
                    }}
                    color="#fff"
                    w="max-content"
                  >
                    Add Schedule
                  </Button>
                  <Button
                    variant="danger"
                    size="md"
                    px={10}
                    onClick={() => {
                      setStatus("delete");
                      onOpen();
                    }}
                    color="#fff"
                    w="max-content"
                  >
                    Delete Event
                  </Button>
                </HStack>
                <Box rounded={10} pt={10} bg="#fff">
                  <Calendar
                    localizer={localizer}
                    defaultDate={new Date()}
                    defaultView="month"
                    events={event}
                    style={{ height: "100vh" }}
                  />
                </Box>
              </Stack>
            )}
          </>
        )}
        <ScheduleDrawer
          onClose={onClose}
          isOpen={isOpen}
          createSchedule={createSchedule}
          getSchedule={getSchedule}
          status={status}
        />
      </DashboardWrapper>
    </FadeInPage>
  );
}

export default Schedule;
