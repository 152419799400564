import { Heading, Text, Button, ButtonGroup } from "@chakra-ui/react";
import DongleWrapper from "../components/DongleWrapper";

const NoDongle = ({ setVerifyState }) => {
  return (
    <DongleWrapper>
      <>
        <Heading fontSize="sm">Do you have a dongle?</Heading>
        <Text fontSize="sm">
          To access the complete package that FixBot provides you need to
          purchase and install the FixBot Dongle. Some key features can only be
          accessed by dongle owners.
        </Text>
        <ButtonGroup justifyContent="flex-end">
          <Button
            color="red"
            variant="primaryOutine"
            onClick={() => setVerifyState("no-dongle")}
          >
            No I don't
          </Button>
          <Button onClick={() => setVerifyState("input-dongle")}>Yes</Button>
        </ButtonGroup>
      </>
    </DongleWrapper>
  );
};

export default NoDongle;
