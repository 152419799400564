import React from "react";
import classes from "./DashboardWrapper.module.css";
import { DriverCard, NotificationCard, Button } from "../../components";
import { MenuNav } from "../../containers";
import { HStack, Box, LinkBox, Stack, LinkOverlay } from "@chakra-ui/react";
import useLocalStore from "../../modules/hooks/socket/useLocalStore";
import { USER } from "../../constants/index";

function DashboardWrapper({
  children,
  bottomButton,
  noNotification,
  headerComp = null,
  ...others
}) {
  const { getItem } = useLocalStore();
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.left} overflowX="hidden">
        <Box mb="20px" display={{ base: "block", lg: "none" }}>
          <MenuNav />
        </Box>
        {headerComp && (
          <HStack
            mt="20px"
            mb="30px"
            color="fixbot"
            display={{ base: "none", md: "block" }}
          >
            {headerComp}
          </HStack>
        )}
        <Box pb={5}>{children}</Box>

        {/* <div className={classes.bottomButton}>{bottomButton}</div> */}
      </Box>
      <div className={classes.right}>
        <Stack position="sticky" right={0} top="80px">
          <DriverCard />
          {!getItem(USER.FIXBOT_USER_DEVICES) && (
            <LinkBox as={Box} w="full" mx="auto" textAlign="center">
              <Button alignSelf="center" w="90%" variant="primary">
                <LinkOverlay
                  href="https://paystack.com/pay/fixbotday1"
                  isExternal
                >
                  Get Dongle
                </LinkOverlay>
              </Button>
            </LinkBox>
          )}
          {/* {!noNotification && (
            <Stack spacing={3} py={4} color="fixbot">
              <HStack justifyContent="space-between">
                <Text>Notification</Text>
                <Text>Clear</Text>
              </HStack>
              <NotificationCard
                message="Your vehicle is having radiator issues, see
            details now!"
              />
              <NotificationCard message="Your vehicle has passed kogi state. Take a look now..." />
            </Stack>
          )}
          <HStack w="full" justify="center">
            {bottomButton}
          </HStack> */}
        </Stack>
      </div>
    </Box>
  );
}

export default DashboardWrapper;

// function baz(z) {
//   console.log(new Error().stack);
// }

// function bar(y) {
//   baz(y + 1)
// }

// function foo(x) {
//   bar(x + 1)
// }
// foo(3)

// return
