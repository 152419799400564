import React from "react";
import { Button, Stack, Heading, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../../constants";
import WelcomeAnim from "../../../animation/Success";

function Welcome({
  message = "Password Reset Succesful",
  failed = false,
  ...others
}) {
  const navigate = useNavigate();

  const goToAuth = () => navigate(PAGES.LOGIN_PAGE);

  return (
    <React.Fragment>
      <Stack
        w="90%"
        maxW="400px"
        mx="auto"
        p={10}
        bg="#fff"
        rounded={6}
        alignItems="center"
        spacing={8}
        border="1px solid rgba(0,0,0,0.1)"
        {...others}
      >
        <Stack textAlign="center">
          <Heading as="h3" size="md" color={!failed && "whatsapp.600"}>
            {message}
          </Heading>
          <Text>Use your new password to login to fixbot</Text>
        </Stack>
        <Stack alignItems="center" py={2}>
          <WelcomeAnim />
        </Stack>
        <Stack mt={10} w="full">
          <Button
            onClick={goToAuth}
            py={4}
            w="full"
            px={10}
            fontSize="sm"
            variant="solid"
            bg="gray.900"
            _hover={{ bg: "gray.700" }}
            color="#fff"
            rounded="full"
          >
            Login
          </Button>
        </Stack>
      </Stack>
    </React.Fragment>
  );
}

export default Welcome;
