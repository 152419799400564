import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./SidebarLink.module.css";
import { Box, Button, Tooltip, Image, Text } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";

const MotionBox = motion(Box);

function SidebarLink({
  path,
  title,
  Icon,
  isButton = false,
  isIconMode,
  isIcon,
  onLogoutModal,
}) {
  let body = (
    <>
      <span>
        {isIcon ? <Icon fontSize="18px" /> : <Image maxW="20px" src={Icon} />}
      </span>
      {isIconMode ? null : (
        <AnimatePresence exitBeforeEnter>
          <MotionBox
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.5 }}
          >
            <Text fontSize="0.85rem">{title}</Text>
          </MotionBox>
        </AnimatePresence>
      )}
    </>
  );

  if (isButton) {
    return (
      // <Tooltip hasArrow label={title} placement="right">
      <Button
        w="full"
        justifyItems="start"
        justify="start"
        display="flex"
        rounded={0}
        border={0}
        onClick={onLogoutModal}
        boxShadow="none"
        className={`${
          isIconMode ? classes.sidebarLink__iconMode : classes.sidebarLink
        }`}
      >
        {body}
      </Button>
      // </Tooltip>
    );
  }

  return (
    <Tooltip hasArrow label={title} placement="right" isDisabled={!isIconMode}>
      <NavLink
        className={({ isActive }) =>
          isActive
            ? `${
                isIconMode ? classes.sidebarLink__iconMode : classes.sidebarLink
              } ${classes.active}`
            : `${
                isIconMode ? classes.sidebarLink__iconMode : classes.sidebarLink
              }`
        }
        to={path}
      >
        {body}
      </NavLink>
    </Tooltip>
  );
}

export default SidebarLink;
