import React from "react";
import { Box, Text, Stack, Heading, IconButton } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

function GoalCard({ title, text }) {
  return (
    <Stack w="full" bg="fixbot" px={6} py={7} color="#fff" borderRadius="15px">
      <Box>
        <Heading
          // color={{ base: "#3A3A3A", md: "#fff" }}
          textTransform="capitalize"
          fontSize="md"
          display={{ base: "none", md: "block" }}
        >
          Goals for you
        </Heading>
      </Box>
      <Box w="70%" color="#fff" my="15px">
        <Text color="#fff" fontSize="sm">
          Do you need a mechanic? your car is due for servicing
        </Text>
      </Box>
      <Stack alignItems="flex-end">
        <IconButton
          w="min-content"
          size="sm"
          border={0}
          _hover={{ bg: "transparent" }}
        >
          <FontAwesomeIcon icon={faArrowRightLong} />
        </IconButton>
      </Stack>
    </Stack>
  );
}

export default GoalCard;
