import React from "react";
import { DashboardWrapper } from "../../containers";
import PersonalSettings from "./personal-settings";
import CarSettings from "./car-settings";
import PasswordSetting from "./password-settings";
import SettingBtn from "./SettingBtn/SettingBtn";
import { FadeInPage, IfElse } from "../../components";
import { Stack, HStack, Text, Heading } from "@chakra-ui/react";
import sx from "./setting.module.css";

function Settings() {
  const buttons = [
    {
      title: "Personal Settings",
      section: "personal",
    },
    {
      title: "Car Settings",
      section: "car",
    },
    {
      title: "Password",
      section: "password",
    },
  ];

  const [segment, setSegment] = React.useState("personal");

  return (
    <FadeInPage>
      <DashboardWrapper
        noNotification
        headerComp={
          <Stack spacing={2}>
            <Heading fontSize="2rem">Settings</Heading>
            <Text>
              Schedule car Todos and keep your car maintenance on track.
            </Text>
          </Stack>
        }
      >
        <Stack mt={10} spacing={6} py={2}>
          <HStack
            spacing={4}
            w={{ base: "100%" }}
            mx="auto"
            pb={2}
            justifyContent={{ base: "stretch", md: "center" }}
            className={sx.stats_wrapper}
            overflowX="auto"
          >
            {buttons.map(({ section, title }) => (
              <SettingBtn
                key={section}
                onClick={() => setSegment(section)}
                active={section === segment}
              >
                {title}
              </SettingBtn>
            ))}
          </HStack>
          <Stack w="full">
            <IfElse
              ifOn={segment === "personal"}
              onElse={<CarSettings />}
              ifOnElse={segment === "car"}
              elseThen={<PasswordSetting />}
            >
              <PersonalSettings />
            </IfElse>
          </Stack>
        </Stack>
      </DashboardWrapper>
    </FadeInPage>
  );
}

export default Settings;
