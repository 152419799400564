import React from "react";
import { useToast } from "@chakra-ui/react";

function useErrorHandler(makeQuery, message = "", error = "Login Error") {
  const toast = useToast();
  React.useEffect(() => {
    if (makeQuery.error && makeQuery.isError) {
      toast({
        // title: `Error ${makeQuery?.error?.response?.data?.statusCode || error}`,
        title: `${error}`,
        description: `${makeQuery?.error?.response?.data?.message}`,
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
      makeQuery.reset();
    }

    if (makeQuery.isSuccess && makeQuery.data) {
      toast.closeAll();
      toast({
        title: "Successful",
        description: message,
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makeQuery]);

  return null;
}

export default useErrorHandler;
