import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import json from "./json/94128-welcome.json";

const WelcomeAnim = () => {
  return (
    <Player
      autoplay
      loop
      src={json}
      style={{ height: "270px", width: "300px" }}
    ></Player>
  );
};

export default WelcomeAnim;
