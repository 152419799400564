import React from "react";
import {
  Stack,
  Heading,
  PinInputField,
  PinInput,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import { FadeInPage, Button } from "../../../../components";
import { BiArrowBack } from "react-icons/bi";
import useAddDevice from "../../../hooks/devices/useAddDevice";
import useGetDevices from "../../../hooks/devices/useGetDevices";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../../../constants";

const InputDongleId = ({ setVerifyState }) => {
  const [dongleId, setDongleId] = React.useState("");
  const navigate = useNavigate();
  const getDevice = useGetDevices();
  const addDevice = useAddDevice();
  const handleChange = (value) => {
    setDongleId(value);
  };

  const handlerSubmit = (e) => {
    e.preventDefault();
    addDevice
      .mutateAsync({ dongleId })
      .then(() => getDevice.refetch())
      .then(() => {
        setTimeout(() => {
          navigate(PAGES.DASHBOARD_PAGE);
        }, 3000);
      });
  };

  return (
    <FadeInPage>
      <Stack
        maxW="650px"
        w="96%"
        margin="auto"
        alignItems="stretch"
        spacing={8}
      >
        <Stack>
          <IconButton
            w="max-content"
            onClick={() => setVerifyState("have-dongle")}
          >
            <BiArrowBack />
          </IconButton>
        </Stack>

        <Heading
          as="h2"
          fontSize={{ base: "1.4rem", md: "1.8rem" }}
          textAlign="center"
        >
          Input Dongle ID
        </Heading>
        <Stack
          bg="#fff"
          px={{ base: 4, md: 8 }}
          py={8}
          spacing={12}
          as="form"
          onSubmit={handlerSubmit}
        >
          <HStack justifyContent="center" spacing={4}>
            <PinInput
              size="lg"
              focusBorderColor="fixbot"
              autoFocus
              spacing={4}
              onChange={handleChange}
            >
              {Array.from(Array(6)).map((_f, i) => (
                <PinInputField key={i} />
              ))}
            </PinInput>
          </HStack>

          <Stack pt={2}>
            <Button
              type="submit"
              py={6}
              isDisabled={!(dongleId.length === 6)}
              isLoading={addDevice.isLoading}
            >
              Confirm
            </Button>
          </Stack>
        </Stack>
        {/* <Stack alignItems="center" mt={4}>
          <Text textAlign="center" fontSize="sm">
            Forgot Dongle ID?{"  "}
            <Link to="register">
              <Text as="strong" color="fixbot">
                Retrieve it
              </Text>
            </Link>
          </Text>
        </Stack> */}
      </Stack>
    </FadeInPage>
  );
};

export default InputDongleId;
