import React from "react";
import {
  Heading,
  Text,
  Spinner,
  SimpleGrid,
  Button,
  Box,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Stack,
  HStack,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import { CustomSelect, Input } from "../../components";
import useValidateAuth from "../../modules/hooks/auth/useValidateAuthFields";
import { RRule, rrulestr } from "rrule";
import useLocalStore from "../../modules/hooks/socket/useLocalStore";
import { USER } from "../../constants";
import { MdDeleteOutline } from "react-icons/md";
import useDeleteSchedule from "../../modules/hooks/schedule/useDeleteSchedule";

const daysData = [
  { value: RRule.MO, text: "Mon" },
  { value: RRule.TU, text: "Tue" },
  { value: RRule.WE, text: "Wed" },
  { value: RRule.TH, text: "Thurs" },
  { value: RRule.FR, text: "Fri" },
  { value: RRule.SA, text: "Sat" },
  { value: RRule.SU, text: "Sun" },
];

const getStartDay = (dayIndex) => {
  return daysData[+dayIndex].value;
};

function ScheduleModal({
  onClose,
  isOpen,
  createSchedule,
  getSchedule,
  status,
}) {
  const [days, setDays] = React.useState([RRule.MO]);
  const { getItem } = useLocalStore();
  const device = getItem(USER.FIXBOT_USER_DEVICES);
  const [id, setId] = React.useState("");
  const deleteSchedule = useDeleteSchedule(id);
  const toast = useToast();

  const selectDaysHandler = (value) => {
    if (days.includes(value)) {
      setDays((prev) => prev.filter((text) => text !== value));
    } else {
      setDays((prev) => [...prev, value]);
    }
  };

  React.useEffect(() => {
    if (id !== "") {
      deleteSchedule.reset();
      deleteSchedule.mutateAsync().then(() => {
        getSchedule.refetch();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const formik = useValidateAuth(
    {
      task: "",
      freq: RRule.DAILY,
      startDateTime: "",
      endDateTime: "",
    },
    handleLogin
  );
  function handleLogin(values) {
    const reqRrule = new RRule({
      freq: +values.freq,
      dtstart: new Date(values.startDateTime),
      until: new Date(values.endDateTime),
      wkst: getStartDay(new Date(values.startDateTime).getDay()),
      byweekday: days,
    });

    if (device[0]?.dongleId) {
      const body = {
        dongleId: device[0]?.dongleId,
        event: values.task,
        rrules: reqRrule.toString(),
        startDateTime: new Date(values.startDateTime).toString(),
        endDateTime: new Date(values.endDateTime).toString(),
      };

      createSchedule
        .mutateAsync(body)
        .then(getSchedule.refetch)
        .then(() => {
          onClose();
          formik.resetForm();
          setDays([]);
        });
      return;
    }
    toast({
      title: "No Dongle detected",
      description: `Event can't be added to an account without a dongle`,
      status: "warning",
      position: "top-right",
      duration: 5000,
      isClosable: true,
    });
  }
  return (
    <>
      <Drawer
        closeOnOverlayClick={false}
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="sm"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton size="sm" rounded="full" />
          <DrawerHeader borderBottom="1px solid rgba(0,0,0,0.1)">
            {status === "add" ? "Create" : "Delete"} Schedule
          </DrawerHeader>

          <DrawerBody>
            {status === "add" ? (
              <Box
                w="full"
                mx="auto"
                pt={8}
                as="form"
                onSubmit={formik.handleSubmit}
              >
                <Stack spacing={4}>
                  <Input
                    label="task"
                    placeholder="Add a task"
                    name="task"
                    {...formik.getFieldProps("task")}
                    touched={formik.touched.task}
                    errors={formik.errors.task}
                  />

                  <CustomSelect
                    label="frequency"
                    options={[
                      { value: RRule.DAILY, name: "Daily" },
                      { value: RRule.WEEKLY, name: "Weekly" },
                      { value: RRule.MONTHLY, name: "Monthly" },
                      { value: RRule.YEARLY, name: "Yearly" },
                    ]}
                    name="freq"
                    {...formik.getFieldProps("freq")}
                    touched={formik.touched.freq}
                    errors={formik.errors.freq}
                  />

                  <Stack>
                    <Input
                      label="Start Time"
                      type="datetime-local"
                      name="startDateTime"
                      // min={`${new Date().getFullYear()}-06-30T10:34`}
                      {...formik.getFieldProps("startDateTime")}
                      touched={formik.touched.startDateTime}
                      errors={formik.errors.startDateTime}
                    />

                    <Input
                      type="datetime-local"
                      label="End Time"
                      name="endDateTime"
                      {...formik.getFieldProps("endDateTime")}
                      touched={formik.touched.endDateTime}
                      errors={formik.errors.endDateTime}
                    />
                  </Stack>

                  <Stack pt={2} pr={4}>
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      alignItems="center"
                      display="inline-flex"
                      mb={-1}
                    >
                      Select Days
                    </Text>
                    <HStack flexWrap="wrap" spacing={3}>
                      <SimpleGrid
                        columns={{ base: 3, md: 4 }}
                        columnGap={10}
                        rowGap={3}
                      >
                        {daysData.map(({ value, text }) => (
                          <Button
                            px={6}
                            size="sm"
                            py={5}
                            key={value}
                            rounded={24}
                            onClick={() => selectDaysHandler(value)}
                            bg={days.includes(value) ? "fixbot" : "auto"}
                            color={days.includes(value) ? "#fff" : "auto"}
                            _hover={{ color: "#444", bg: "gray.100" }}
                            w="max-content"
                            minW="90px"
                          >
                            {text}
                          </Button>
                        ))}
                      </SimpleGrid>
                    </HStack>
                  </Stack>
                </Stack>
                <Stack pt={7}>
                  <Button
                    variant="primary"
                    isLoading={createSchedule.isLoading}
                    loadingText="Creating task"
                    type="submit"
                    _disabled={{
                      opacity: 0.5,
                      background: "#1c75bb",
                      cursor: "not-allowed",
                      boxShadow: "none",
                    }}
                  >
                    Create Task
                  </Button>
                </Stack>
              </Box>
            ) : (
              <Stack pt={10} spacing={3}>
                {getSchedule.value?.maintenanceSchedules &&
                getSchedule.value?.maintenanceSchedules?.length > 0 ? (
                  getSchedule.value.maintenanceSchedules.map((item) => (
                    <Stack key={item._id}>
                      <HStack>
                        <Stack
                          border="1px solid rgba(0,0,0,.1)"
                          shadow="sm"
                          rounded={10}
                          p={3}
                          w="full"
                        >
                          <Text
                            fontSize="md"
                            color="gray.800"
                            fontWeight="bold"
                          >
                            {item.event}
                          </Text>
                          <Text
                            color="gray.400"
                            lineHeight={1.5}
                            textTransform="capitalize"
                          >
                            {rrulestr(item.rrules).toText()}
                          </Text>
                        </Stack>
                        <Box>
                          <IconButton
                            size="sm"
                            onClick={() => {
                              setId(item._id);
                            }}
                            rounded="full"
                            color="red.500"
                          >
                            {deleteSchedule.isLoading && item._id === id ? (
                              <Spinner />
                            ) : (
                              <MdDeleteOutline style={{ fontSize: "18px" }} />
                            )}
                          </IconButton>
                        </Box>
                      </HStack>
                    </Stack>
                  ))
                ) : (
                  <Heading>No event set for this user</Heading>
                )}
              </Stack>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default ScheduleModal;
