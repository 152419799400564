import React from "react";
import classes from "./Sidebar.module.css";
import { Box, Image, VStack } from "@chakra-ui/react";
import { SidebarLink } from "../../components";
import { sidebarData } from "../../constants/sidebarData";
import { motion } from "framer-motion";

const MotionBox = motion(Box);
const MotionStack = motion(VStack);

function Sidebar({ isSidebarOpen, onOpen }) {
  return (
    <MotionBox
      layout
      className={`${classes.sidebar}`}
      w={!isSidebarOpen ? "90px" : "210px"}
    >
      <Box className={classes.sidebar__top}>
        <MotionStack layout pl={3} h="55px" align="start" justify="center">
          <Image
            h={isSidebarOpen ? "30px" : "50%"}
            objectFit="cover"
            src="/Images/newlogo.png"
            alt="fixbot logo"
          />
        </MotionStack>

        <Box sx={{ marginTop: "20px" }} align="center" justify="center">
          {sidebarData.map((link) => (
            <SidebarLink
              key={link.title}
              isIconMode={!isSidebarOpen}
              {...link}
              onLogoutModal={onOpen}
            />
          ))}
        </Box>
      </Box>
    </MotionBox>
  );
}

export default Sidebar;
