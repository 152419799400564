import mapStyles from "./mapStyles";

export const containerStyle = {
  width: "100%",
  height: "100%",
  // minHeight: ""
};

// Center on Kalman
export const center = {
  lat: 6.68,
  lng: 3.33,
};

// Disable default UI
export const options = {
  // styles: mapStyles,
  // disableDefaultUI: true,
  clickableIcons: false,
  zoomControl: true,
};
