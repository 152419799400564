import React from "react";
import { AuthWrapper } from "../../../containers";
import { IfElse } from "../../../components";
import { Stack } from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";
import useVerifyAccount from "../../hooks/auth/useVerifyAccount";
import Verified from "./Verified";
import useErrorHandler from "../../../lib/hooks/useErrorHandler";
import useGetToken from "../../hooks/account/useGetToken";

function VerifyAccount() {
  const verifyAcc = useVerifyAccount();
  useErrorHandler(verifyAcc, "Account Verified");
  const { token, tokenData } = useGetToken();

  React.useEffect(() => {
    if (token) {
      verifyAcc.mutateAsync({ token });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthWrapper isVerify={true}>
      <IfElse
        ifOn={
          verifyAcc.status === "loading" ||
          (!verifyAcc.data && !verifyAcc.error)
        }
        ifOnElse={verifyAcc.status === "success"}
        onElse={<Verified email={tokenData?.email} />}
        elseThen={<Verified message="Account Verification Failed" failed />}
      >
        <Stack w="full" textAlign="center">
          <BeatLoader size={30} color="#1B5246" margin={2} />
        </Stack>
      </IfElse>
    </AuthWrapper>
  );
}

export default VerifyAccount;
