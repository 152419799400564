import { Player } from "@lottiefiles/react-lottie-player";
import json from "./json/emailSent.json";
import React from "react";

const EmailSent = () => {
  return (
    <Player
      autoplay
      loop
      src={json}
      //	src="https://assets8.lottiefiles.com/packages/lf20_Stt1R6.json"
      style={{ width: "200px", margin: "inherit" }}
    />
  );
};

export default EmailSent;
