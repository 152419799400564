import React from "react";
import classes from "./Map.module.css";
import { AspectRatio, Stack, Spinner, HStack, Text } from "@chakra-ui/react";
import FixbotMap from "./index";
import { useJsApiLoader } from "@react-google-maps/api";
import { useSocketContext } from "../../lib/context/global/SocketContext";

const Map = React.memo(() => {
  const libraries = React.useMemo(() => ["places"], []);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyD5V-TBDxs0hFghJoxEwpSEm24peGCzM5s",
    libraries,
  });
  const { location, setLocation, setFirstLocation } = useSocketContext();

  return (
    <>
      <HStack
        flexDirection={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        spacing={{ base: "0", md: "2" }}
      >
        <Text>Current Location: </Text>
        <Text color="fixbot" fontSize="sm" p={0} m={0}>
          {location}
        </Text>
      </HStack>
      <AspectRatio minH="90vh" ratio={16 / 9} className={classes.map__inner}>
        {!isLoaded ? (
          <Stack
            pos="absolute"
            left={0}
            top={0}
            w="full"
            h="full"
            bg="rgba(0,0,0,0.2)"
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Stack>
        ) : (
          <FixbotMap
            setLocation={setLocation}
            setFirstLocation={setFirstLocation}
          />
        )}
      </AspectRatio>
    </>
  );
});

export default Map;

// import React from "react";
// import {
//   GoogleMap,
//   Marker,
//   InfoWindow,
//   useJsApiLoader,
//   Circle,
//   MarkerClusterer,
//   DirectionsRenderer,
// } from "@react-google-maps/api";
// import { Stack } from "@chakra-ui/react";
// import { containerStyle, center, options } from "./utils/settings";
// import useGetGpsData from "../../modules/hooks/socket/useGetGpsData";

// function FixbotMap() {
//   // Save map in ref if we want to access the map
//   const mapRef = React.useRef(null);
//   const gpsData = useGetGpsData();

//   // const setMarker = React.useCallback(() => {
//   //   if (gpsData) {
//   //     mapRef.current?.panTo({
//   //       lat: gpsData.latitude,
//   //       lng: gpsData.longitude,
//   //     });
//   //   }
//   // }, [gpsData]);

//   const onLoad = React.useCallback((map) => {
//     mapRef.current = map;
//     // setMarker();
//   }, []);

//   const unMount = React.useCallback(() => {
//     mapRef.current = null;
//   }, []);

//   return (
//     <Stack h="full">
//       <GoogleMap
//         mapContainerStyle={containerStyle}
//         options={options}
//         center={center}
//         zoom={12}
//         onLoad={onLoad}
//         onUnMount={unMount}
//       >
//         {/* {gpsData ? (
//           <Marker
//             position={{ lat: gpsData.latitude, lng: gpsData.longitude }}
//           />
//         ) : null} */}
//       </GoogleMap>
//     </Stack>
//   );
// }

// export default FixbotMap;

// const defaultOptions = {
//   strokeOpacity: 0.5,
//   strokeWeight: 2,
//   clickable: false,
//   draggable: false,
//   editable: false,
//   visible: true,
// };

// const closeOptions = {
//   ...defaultOptions,
//   zIndex: 3,
//   fillOpacity: 0.05,
//   strokeColor: "#8bc34a",
//   fillColor: "#8bc34a",
// };

// const middleOptions = {
//   ...defaultOptions,
//   zIndex: 2,
//   fillOpacity: 0.05,
//   strokeColor: "#fbc02d",
//   fillColor: "#fbc02d",
// };

// const farOptions = {
//   ...defaultOptions,
//   zIndex: 1,
//   fillOpacity: 0.05,
//   strokeColor: "#ff5252",
//   fillColor: "#ff5252",
// };

// const generateHouses = (position) => {
//   const _houses = [];
//   for (let i = 0; i < 100; i++) {
//     const direction = Math.random() < 0.5 ? -2 : 2;
//     _houses.push({
//       lat: position.lat + Math.random() / direction,
//       lng: position.lng + Math.random() / direction,
//     });
//   }

//   return _houses;
// };
