import React from "react";
import {
  Image,
  Text,
  Stack,
  ButtonGroup,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";
import cautionImg from "../../../../assets/svg/caution.svg";
import { Button } from "../../../../components";
import DongleWrapper from "../components/DongleWrapper";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../../../constants";

const NoDongle = ({ setVerifyState }) => {
  const navigate = useNavigate();
  return (
    <DongleWrapper onClick={() => setVerifyState("have-dongle")}>
      <>
        <Stack>
          <Image h="60px" src={cautionImg} />
        </Stack>
        <Text fontSize="sm">
          Without the FixBot dongle important data from your car cannot be
          accessed. You can continue anyways or move to purchase dongle.
        </Text>
        <ButtonGroup justifyContent="flex-end">
          <Button
            variant="primary"
            onClick={() => navigate(PAGES.DASHBOARD_PAGE)}
          >
            Continue Without Dongle
          </Button>
          <LinkBox>
            <Button variant="primary">
              <LinkOverlay
                href="https://paystack.com/pay/fixbotday1"
                isExternal
              >
                Get Dongle
              </LinkOverlay>
            </Button>
          </LinkBox>
        </ButtonGroup>
      </>
    </DongleWrapper>
  );
};

export default NoDongle;
