import React from "react";
import { HStack, Text, Stack, Heading } from "@chakra-ui/react";
import useResetPassword from "../../hooks/auth/useResetPassword";
import { AuthWrapper } from "../../../containers";
import { Input, Button as CustomButton, IfElse } from "../../../components";
import useValidateAuth from "../../hooks/auth/useValidateAuthFields";
import useErrorHandler from "../../../lib/hooks/useErrorHandler";
import Success from "./Success";
import useGetToken from "../../hooks/account/useGetToken";

const ResetPassword = () => {
  const resetPassword = useResetPassword();
  useErrorHandler(resetPassword, "Password reset Successfully");
  const { token } = useGetToken();

  const handleReset = (values) => {
    resetPassword.mutateAsync({
      token,
      password: values.password,
    });
  };

  const formik = useValidateAuth(
    {
      passowrd: "",
    },
    handleReset
  );

  return (
    <AuthWrapper isVerify>
      <IfElse
        ifOn={
          resetPassword.status === "loading" ||
          (!resetPassword.data && !resetPassword.error)
        }
        ifOnElse={resetPassword.status === "success"}
        onElse={<Success />}
      >
        <Stack
          maxW="550px"
          w="96%"
          margin="auto"
          alignItems="stretch"
          spacing={8}
          as="form"
          onSubmit={formik.handleSubmit}
        >
          <Stack textAlign="center">
            <Heading
              as="h2"
              fontSize={{ base: "1.4rem", md: "1.8rem" }}
              textAlign="center"
            >
              Reset Password
            </Heading>
            <Text>Please enter your new password</Text>
          </Stack>
          <Stack bg="#fff" px={{ base: 4, md: 8 }} py={8} spacing={5}>
            <Stack mb={6}>
              <Input
                name="password"
                label="New Password"
                type="password"
                {...formik.getFieldProps("password")}
                touched={formik.touched.password}
                errors={formik.errors.password}
              />
            </Stack>
            <HStack>
              <CustomButton
                type="submit"
                flex={4}
                py={6}
                isLoading={resetPassword.isLoading && !resetPassword.error}
              >
                Set new password
              </CustomButton>
            </HStack>
          </Stack>
        </Stack>
      </IfElse>
    </AuthWrapper>
  );
};

export default ResetPassword;
