import React from "react";
import PropTypes from "prop-types";
import { Box, Text, Heading, Divider, Grid, Image } from "@chakra-ui/react/";
import FadeInPage from "../FadeInPage/FadeInPage";
import carBlob from "../../assets/svg/car.svg";
const FaultDialogue = ({ fault }) => {
  return (
    fault &&
    Object.keys(fault).length > 0 && (
      <FadeInPage w="100%">
        {Object.keys(fault).map((item) => (
          <Box
            key={item}
            my="1rem"
            bg={{
              sm: "none",
              md: "none",
              lg: "#fff",
              xl: "#fff",
              base: "none",
            }}
            borderRadius="14px"
            p={{
              sm: "0rem",
              md: "1.5rem",
              lg: "1.5rem",
              xl: "1.5rem",
              base: "0rem",
            }}
          >
            <Heading as="h5" size="sm">
              <Text
                fontSize={{
                  sm: "1rem",
                  md: "1rem",
                  lg: "1.2rem",
                  xl: "1.2rem",
                  base: "1rem",
                }}
                color="#68768B"
                fontWeight="500"
                textTransform="uppercase"
              >
                {" "}
                fault
              </Text>
            </Heading>
            <Heading as="h3" size="xl" mt="1.5rem">
              <Text
                fontSize={{
                  sm: "1.2rem",
                  md: "1.2rem",
                  lg: "1.8rem",
                  xl: "1.8rem",
                  base: "1.2rem",
                }}
                color="#213654"
                fontWeight="600"
                lineHeight="1.2"
              >
                {fault[item].carmd.desc && fault[item].carmd.desc}
              </Text>
            </Heading>
            <Heading as="h5" size="sm" mt=".7rem">
              <Text
                fontSize={{
                  sm: "1rem",
                  md: "1rem",
                  lg: "1.2rem",
                  xl: "1.2rem",
                  base: "1rem",
                }}
                color="#68768B"
                fontWeight="500"
              >
                {fault[item].carmd.code && fault[item].carmd.code}
              </Text>
            </Heading>
            <Heading as="h3" size="xl" mt="2rem">
              <Text
                fontSize={{
                  sm: "1.2rem",
                  md: "1.2rem",
                  lg: "1.8rem",
                  xl: "1.8rem",
                  base: "1.2rem",
                }}
                color="#213654"
                fontWeight="600"
              >
                Urgency{" "}
                <Text
                  d="inline"
                  borderRadius="5px"
                  fontSize={{
                    sm: "1rem",
                    md: "1rem",
                    lg: "1.2rem",
                    xl: "1.2rem",
                    base: "1rem",
                  }}
                  bg="red.300"
                  color="#fff"
                  p=".2rem"
                >
                  {fault[item].carmd.urgency && fault[item].carmd.urgency}%
                </Text>
              </Text>
            </Heading>
            {fault[item].carmd.urgency_desc && (
              <Heading as="h5" size="sm" mt="1rem">
                <Text
                  fontSize={{
                    sm: "1rem",
                    md: "1rem",
                    lg: "1.2rem",
                    xl: "1.2rem",
                    base: "1rem",
                  }}
                  color="#68768B"
                  fontWeight="500"
                  lineHeight="1.5"
                >
                  {fault[item].carmd.urgency_desc}
                </Text>
              </Heading>
            )}
            {fault[item].carmd.effect_on_vehicle && (
              <>
                <Heading as="h3" size="xl" mt="2rem">
                  <Text
                    fontSize={{
                      sm: "1.2rem",
                      md: "1.2rem",
                      lg: "1.8rem",
                      xl: "1.8rem",
                      base: "1.2rem",
                    }}
                    color="#213654"
                    fontWeight="600"
                  >
                    Possible effects on vehicle
                  </Text>
                </Heading>
                <Heading as="h5" size="sm" mt="1rem">
                  <Text
                    fontSize={{
                      sm: "1rem",
                      md: "1rem",
                      lg: "1.2rem",
                      xl: "1.2rem",
                      base: "1rem",
                    }}
                    color="#68768B"
                    fontWeight="500"
                    lineHeight="1.5"
                  >
                    {fault[item].carmd.effect_on_vehicle}
                  </Text>
                </Heading>
              </>
            )}
            {fault[item].carmd.responsible_system && (
              <>
                <Heading as="h3" size="xl" mt="2rem">
                  <Text
                    fontSize={{
                      sm: "1.2rem",
                      md: "1.2rem",
                      lg: "1.8rem",
                      xl: "1.8rem",
                      base: "1.2rem",
                    }}
                    color="#213654"
                    fontWeight="600"
                  >
                    Sensor Information
                  </Text>
                </Heading>
                <Heading as="h5" size="sm" mt="1rem">
                  <Text
                    fontSize={{
                      sm: "1rem",
                      md: "1rem",
                      lg: "1.2rem",
                      xl: "1.2rem",
                      base: "1rem",
                    }}
                    color="#68768B"
                    fontWeight="500"
                    lineHeight="1.5"
                  >
                    {fault[item].carmd.responsible_system}
                  </Text>
                </Heading>
              </>
            )}

            {fault[item].carmd.layman_definition && (
              <>
                <Heading as="h3" size="xl" mt="2rem">
                  <Text
                    fontSize={{
                      sm: "1.2rem",
                      md: "1.2rem",
                      lg: "1.8rem",
                      xl: "1.8rem",
                      base: "1.2rem",
                    }}
                    color="#213654"
                    fontWeight="600"
                  >
                    Layman Defination
                  </Text>
                </Heading>
                <Heading as="h5" size="sm" mt="1rem">
                  <Text
                    fontSize={{
                      sm: "1rem",
                      md: "1rem",
                      lg: "1.2rem",
                      xl: "1.2rem",
                      base: "1rem",
                    }}
                    color="#68768B"
                    fontWeight="500"
                    lineHeight="1.5"
                  >
                    {fault[item].carmd.layman_definition}
                  </Text>
                </Heading>
              </>
            )}

            {fault[item].carmd.tech_definition && (
              <>
                <Heading as="h3" size="xl" mt="2rem">
                  <Text
                    fontSize={{
                      sm: "1.2rem",
                      md: "1.2rem",
                      lg: "1.8rem",
                      xl: "1.8rem",
                      base: "1.2rem",
                    }}
                    color="#213654"
                    fontWeight="600"
                  >
                    Tech Defination
                  </Text>
                </Heading>
                <Heading as="h5" size="sm" mt="1rem">
                  <Text
                    fontSize={{
                      sm: "1rem",
                      md: "1rem",
                      lg: "1.2rem",
                      xl: "1.2rem",
                      base: "1rem",
                    }}
                    color="#68768B"
                    fontWeight="500"
                    lineHeight="1.5"
                  >
                    {fault[item].carmd.tech_definition}
                  </Text>
                </Heading>
              </>
            )}

            {fault[item].carmd.repair.difficulty && (
              <>
                <Heading as="h3" size="xl" mt="2rem">
                  <Text
                    fontSize={{
                      sm: "1.2rem",
                      md: "1.2rem",
                      lg: "1.8rem",
                      xl: "1.8rem",
                      base: "1.2rem",
                    }}
                    color="#213654"
                    fontWeight="600"
                  >
                    Repair Difficulty
                  </Text>
                </Heading>
                <Heading as="h5" size="sm" mt="1rem">
                  <Text
                    d="inline-block"
                    borderRadius="5px"
                    fontSize={{
                      sm: "1rem",
                      md: "1rem",
                      lg: "1.2rem",
                      xl: "1.2rem",
                      base: "1rem",
                    }}
                    bg="red.300"
                    color="#fff"
                    p=".2rem"
                    lineHeight="1.5"
                  >
                    {fault[item].carmd.repair.difficulty}
                  </Text>
                </Heading>
              </>
            )}
            {fault[item].carmd.repair.hours && (
              <>
                <Heading as="h3" size="xl" mt="2rem">
                  <Text
                    fontSize={{
                      sm: "1.2rem",
                      md: "1.2rem",
                      lg: "1.8rem",
                      xl: "1.8rem",
                      base: "1.2rem",
                    }}
                    color="#213654"
                    fontWeight="600"
                  >
                    Approximate time for repairs
                  </Text>
                </Heading>
                <Heading as="h5" size="sm" mt="1rem">
                  <Text
                    fontSize={{
                      sm: "1rem",
                      md: "1rem",
                      lg: "1.2rem",
                      xl: "1.2rem",
                      base: "1rem",
                    }}
                    color="#68768B"
                    fontWeight="500"
                    lineHeight="1.5"
                  >
                    {fault[item].carmd.repair.hours} h/r
                  </Text>
                </Heading>
              </>
            )}
            {fault[item].carmd.repair.labor_rate_per_hour && (
              <>
                <Heading as="h3" size="xl" mt="2rem">
                  <Text
                    fontSize={{
                      sm: "1.2rem",
                      md: "1.2rem",
                      lg: "1.8rem",
                      xl: "1.8rem",
                      base: "1.2rem",
                    }}
                    color="#213654"
                    fontWeight="600"
                  >
                    Labour rate per hour
                  </Text>
                </Heading>
                <Heading as="h5" size="sm" mt="1rem">
                  <Text
                    fontSize={{
                      sm: "1rem",
                      md: "1rem",
                      lg: "1.2rem",
                      xl: "1.2rem",
                      base: "1rem",
                    }}
                    color="#68768B"
                    fontWeight="500"
                    lineHeight="1.5"
                  >
                    {fault[item].carmd.repair.labor_rate_per_hour} h/r
                  </Text>
                </Heading>
              </>
            )}
            {fault[item].carmd.repair.part_cost && (
              <>
                <Heading as="h3" size="xl" mt="2rem">
                  <Text
                    fontSize={{
                      sm: "1.2rem",
                      md: "1.2rem",
                      lg: "1.8rem",
                      xl: "1.8rem",
                      base: "1.2rem",
                    }}
                    color="#213654"
                    fontWeight="600"
                  >
                    Part Cost
                  </Text>
                </Heading>
                <Heading as="h5" size="sm" mt="1rem">
                  <Text
                    fontSize={{
                      sm: "1rem",
                      md: "1rem",
                      lg: "1.2rem",
                      xl: "1.2rem",
                      base: "1rem",
                    }}
                    color="#68768B"
                    fontWeight="500"
                    lineHeight="1.5"
                  >
                    $ {fault[item].carmd.repair.part_cost}
                  </Text>
                </Heading>
              </>
            )}
            {fault[item].carmd.repair.labor_cost && (
              <>
                <Heading as="h3" size="xl" mt="2rem">
                  <Text
                    fontSize={{
                      sm: "1.2rem",
                      md: "1.2rem",
                      lg: "1.8rem",
                      xl: "1.8rem",
                      base: "1.2rem",
                    }}
                    color="#213654"
                    fontWeight="600"
                  >
                    Labour Cost
                  </Text>
                </Heading>
                <Heading as="h5" size="sm" mt="1rem">
                  <Text
                    fontSize={{
                      sm: "1rem",
                      md: "1rem",
                      lg: "1.2rem",
                      xl: "1.2rem",
                      base: "1rem",
                    }}
                    color="#68768B"
                    fontWeight="500"
                    lineHeight="1.5"
                  >
                    $ {fault[item].carmd.repair.labor_cost}
                  </Text>
                </Heading>
              </>
            )}
            {fault[item].carmd.repair.misc_cost && (
              <>
                <Heading as="h3" size="xl" mt="2rem">
                  <Text
                    fontSize={{
                      sm: "1.2rem",
                      md: "1.2rem",
                      lg: "1.8rem",
                      xl: "1.8rem",
                      base: "1.2rem",
                    }}
                    color="#213654"
                    fontWeight="600"
                  >
                    Misc cost
                  </Text>
                </Heading>
                <Heading as="h5" size="sm" mt="1rem">
                  <Text
                    fontSize={{
                      sm: "1rem",
                      md: "1rem",
                      lg: "1.2rem",
                      xl: "1.2rem",
                      base: "1rem",
                    }}
                    color="#68768B"
                    fontWeight="500"
                    lineHeight="1.5"
                  >
                    $ {fault[item].carmd.repair.misc_cost}
                  </Text>
                </Heading>
              </>
            )}
            {fault[item].carmd.repair.total_cost && (
              <>
                <Heading as="h3" size="xl" mt="2rem">
                  <Text
                    fontSize={{
                      sm: "1.2rem",
                      md: "1.2rem",
                      lg: "1.8rem",
                      xl: "1.8rem",
                      base: "1.2rem",
                    }}
                    color="#213654"
                    fontWeight="600"
                  >
                    Total repair cost
                  </Text>
                </Heading>
                <Heading as="h5" size="sm" mt="1rem">
                  <Text
                    fontSize={{
                      sm: "1rem",
                      md: "1rem",
                      lg: "1.2rem",
                      xl: "1.2rem",
                      base: "1rem",
                    }}
                    color="#68768B"
                    fontWeight="500"
                    lineHeight="1.5"
                  >
                    $ {fault[item].carmd.repair.total_cost}
                  </Text>
                </Heading>
              </>
            )}

            {fault[item].carmd.parts && fault[item].carmd.parts.length > 0 && (
              <>
                <Heading as="h3" size="xl" mt="2rem">
                  <Text
                    fontSize={{
                      sm: "1.2rem",
                      md: "1.2rem",
                      lg: "1.8rem",
                      xl: "1.8rem",
                      base: "1.2rem",
                    }}
                    color="#213654"
                    fontWeight="600"
                  >
                    Parts To be fixed
                  </Text>
                </Heading>
                <Grid
                  my="1rem"
                  templateColumns={{
                    sm: "repeat(1, 1fr)",
                    md: "repeat(1, 1fr)",
                    lg: "repeat(2, 1fr)",
                    xl: "repeat(2, 1fr)",
                    base: "repeat(1, 1fr)",
                  }}
                  gridGap="1rem"
                >
                  {fault[item].carmd.parts.map((part) => (
                    <Box key={part.price} borderRadius="8px" bg="#213654">
                      <Image
                        src={carBlob}
                        w="100%"
                        h="200px"
                        borderTopLeftRadius="8px"
                        borderTopRightRadius="8px"
                        objectFit="cover"
                        alt={carBlob}
                      />
                      <Box p="1rem">
                        <Heading as="h5" size="sm">
                          <Text
                            fontSize={{
                              sm: ".8rem",
                              md: ".8rem",
                              lg: "1rem",
                              xl: "1rem",
                              base: ".8rem",
                            }}
                            color="#fff"
                            fontWeight="700"
                            lineHeight="1.5"
                          >
                            Name: {part.desc}
                          </Text>
                        </Heading>
                        <Heading as="h5" size="sm">
                          <Text
                            fontSize={{
                              sm: ".8rem",
                              md: ".8rem",
                              lg: "1rem",
                              xl: "1rem",
                              base: ".8rem",
                            }}
                            color="#fff"
                            fontWeight="700"
                            lineHeight="1.5"
                          >
                            Manufacturer: {part.manufacturer}
                          </Text>
                        </Heading>
                        <Heading as="h5" size="sm">
                          <Text
                            fontSize={{
                              sm: ".8rem",
                              md: ".8rem",
                              lg: "1rem",
                              xl: "1rem",
                              base: ".8rem",
                            }}
                            color="#fff"
                            fontWeight="700"
                            lineHeight="1.5"
                          >
                            Price: ${part.price}
                          </Text>
                        </Heading>
                        <Heading as="h5" size="sm">
                          <Text
                            fontSize={{
                              sm: ".8rem",
                              md: ".8rem",
                              lg: "1rem",
                              xl: "1rem",
                              base: ".8rem",
                            }}
                            color="#fff"
                            fontWeight="700"
                            lineHeight="1.5"
                          >
                            Quantity: {part.qty}
                          </Text>
                        </Heading>
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </>
            )}

            <Divider border="1px solid #68768B" mt="1rem" />
          </Box>
        ))}
      </FadeInPage>
    )
  );
};

FaultDialogue.propTypes = {
  fault: PropTypes.object.isRequired,
};

export default FaultDialogue;
