import { useMemo } from "react";
import { sidebarData } from "../../constants/sidebarData";

function useMenuRoutes() {
  const activeRoute = useMemo(() => {
    if (window.location.pathname) {
      const currentLink = sidebarData.find((link) =>
        window.location.pathname.includes(link.path)
      );
      const currentLinkName = currentLink.title;
      const currentLinkIcon = currentLink.Icon;
      return { currentLinkName, currentLinkIcon };
    }
    return "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return { activeRoute };
}

export default useMenuRoutes;
