import dashboard from "../assets/svg/dashboard.svg";
import logout from "../assets/svg/logout.svg";
import schedule from "../assets/svg/schedule.svg";
import report from "../assets/svg/report.svg";
import routes from "../assets/svg/routes.svg";
import settings from "../assets/svg/settings.svg";
import services from "../assets/svg/services.svg";
import diagnostics from "../assets/svg/diagnostics.svg";
import autoland from "../assets/svg/autoland.svg";
import stats from "../assets/svg/stats.svg";

const sidebarData = [
  { Icon: dashboard, title: "Dashboard", path: `/my/dashboard` },
  { Icon: diagnostics, title: "Diagnostics", path: `/my/diagnostics` },
  {
    Icon: routes,
    title: "Routing",
    path: `/my/routing`,
  },
  {
    Icon: schedule,
    title: "Maintenance Schedule",
    path: `/my/schedule`,
  },

  {
    Icon: stats,
    title: "Vehicle Stats",
    path: `/my/stats`,
  },

  { Icon: autoland, title: "Autoland", path: `/my/autoland` },
  { Icon: report, title: "Report", path: `/my/report` },
  {
    Icon: services,
    title: "Driver's Behaviour Data",
    path: `/my/driver-behavior`,
  },
  { Icon: settings, title: "Settings", path: `/my/settings` },
  { Icon: logout, title: "log out", isButton: true, isLogout: true },
];

export { sidebarData };
