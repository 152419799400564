import React from "react";
import { Stack, Text, Button } from "@chakra-ui/react";
import CustomModal from "../modal-wrapper";
import { useNavigate } from "react-router-dom";
import { PAGES, USER } from "../../../constants";

function LogOutModal({ isOpen, onClose }) {
  const navigate = useNavigate();
  const [isLoading, setLoading] = React.useState(false);

  const handleLogout = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      localStorage.removeItem(USER.FIXBOT_USER_TOKEN);
      // localStorage.removeItem(USER.FIXBOT_USER_DEVICES);
      // localStorage.clear();
      sessionStorage.clear();
      navigate(PAGES.LOGIN_PAGE);
    }, 1000);
  };

  return (
    <CustomModal
      header="Log out of your account"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <Stack pb={3} spacing={5}>
        <Stack spacing={6} alignItems="center" py={3}>
          <Text>Do you want to log out of your account?</Text>

          <Stack
            alignItems="center"
            justifyContent="center"
            direction="row"
            spacing={4}
            pb={4}
            pt={3}
          >
            <Button
              variant="primary"
              bg="red.400"
              _hover={{
                bg: "red.500",
              }}
              border={0}
              onClick={handleLogout}
              isLoading={isLoading}
            >
              Log out
            </Button>

            <Button variant="primary" onClick={onClose}>
              Close
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </CustomModal>
  );
}

export default LogOutModal;
