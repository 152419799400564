import Schedule from "./pages/my/schedule";
import BehaviorData from "./pages/my/service";
import Report from "./pages/my/report";
import Diagnostics from "./pages/my/diagnostics";
import Routing from "./pages/my/routing";
import AutoLand from "./pages/my/autoland";
import Settings from "./pages/my/settings";
import Landing from "./pages/my/dashboard";
import Stats from "./pages/my/stats";
import SingleDiagnostic from "./pages/my/singleDiagnostic";

const routes = [
  { element: <Landing />, path: "dashboard" },

  {
    element: <Diagnostics />,
    path: "diagnostics",
  },
  {
    element: <SingleDiagnostic />,
    path: "diagnostics/faults",
  },
  {
    element: <Routing />,
    path: "routing",
  },
  {
    element: <Schedule />,
    path: "schedule",
  },
  {
    element: <Stats />,
    path: "stats",
  },
  {
    element: <BehaviorData />,
    path: "driver-behavior",
  },
  {
    element: <AutoLand />,
    path: "autoland",
  },
  {
    element: <Report />,
    path: "report",
  },
  {
    element: <Settings />,
    path: "settings",
  },
];

export default routes;
