import React from "react";
import classes from "./Dashboard.module.css";
import { Sidebar, Navbar } from "../../containers";
import { ScrollTop, VerifyModal, LogOutModal } from "../../components";
import { getUserData, storeUserData } from "../../utils/utils";
import { Outlet } from "react-router-dom";
import { useDisclosure, Box } from "@chakra-ui/react";
import SocketContextProvider from "../../lib/context/global/SocketContext";

function Dashboard() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [mail, setMail] = React.useState(false);
  const userData = getUserData();

  const handleVerify = () => {
    storeUserData({ isVerified: true, token: userData.token });
  };

  const closeVerifyModal = () => setMail(true);
  return (
    <>
      <LogOutModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      <SocketContextProvider>
        <VerifyModal
          handleVerify={handleVerify}
          isOpen={!userData.isVerified && !mail}
          onClose={closeVerifyModal}
        />
        <Box className={classes.dashboard__wrapper} bg={{ base: "#ebf3f3" }}>
          <Box
            className={classes.dashboard__smallSidebar}
            display={{ base: "none", lg: "block" }}
          >
            <Sidebar isSidebarOpen={true} onOpen={onOpen} />
          </Box>
          <Box className={classes.dashboard__rightSection} pos="relative">
            <ScrollTop />
            <Navbar onOpen={onOpen} />
            <Outlet />
          </Box>
        </Box>
      </SocketContextProvider>
    </>
  );
}

export default Dashboard;
