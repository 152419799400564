import useCustomMutation from "../../../lib/hooks/useCustomMutation";
import ENDPOINTS, { USER } from "../../../constants";
import { useToast } from "@chakra-ui/react";

function useCreateSchedule() {
  const toast = useToast();

  return useCustomMutation({
    endpoint: ENDPOINTS.API_CREATE_SCHEDULE,
    method: "POST",
    onSettled: (response, err) => {
      !err &&
        toast({
          title: `Successful`,
          description: `Schedule Added`,
          status: "success",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      // console.log(response, "from the useCreateSchedule hook");
    },
    onError: (err) => {
      toast({
        title: `Update Failed`,
        description: `${err?.response?.data?.message}`,
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
      // console.log(err, "from the useCreateSchedule hook");
    },
  });
}

export default useCreateSchedule;
