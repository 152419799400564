import React from "react";
import { Button } from "@chakra-ui/react";

function CustomButton({ children, onClick, ...others }) {
  return (
    <Button
      bg="fixbot"
      color="#fff"
      _hover={{
        bg: "gray.700",
        color: "#fff",
      }}
      spinnerPlacement="end"
      onClick={onClick}
      {...others}
    >
      {children}
    </Button>
  );
}

export default CustomButton;
