import React from "react";
import { useSocketContext } from "../../../lib/context/global/SocketContext";
import useLocalStore from "./useLocalStore";
import { USER } from "../../../constants";

function useGetDriverData() {
  const { socket, device } = useSocketContext();
  const localStore = useLocalStore();

  const [driverData, setDriverData] = React.useState(
    localStore.getItem(USER.FIXBOT_USER_DRIVER_DATA)
  );

  React.useEffect(() => {
    if (!driverData && device.length !== 0 && socket) {
      // socket.emit("monitorDevice", { dongleId: device[0].dongleId });
    }
    socket &&
      socket.on("driversBehavior", (res) => {
        // console.log(res, "from driversBehavior");
        setDriverData(res);
        localStore.setItem(USER.FIXBOT_USER_DRIVER_DATA, res);
      });
    return () => socket && socket.off("driversBehavior");
  }, []);

  return driverData;
}

export default useGetDriverData;
