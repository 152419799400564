import useCustomQuery from "../../../lib/hooks/useCustomQuery";
import ENDPOINTS, { USER } from "../../../constants";
import useLocalStore from "../socket/useLocalStore";

function useGetDevices() {
  const { setItem } = useLocalStore();

  return useCustomQuery({
    endpoint: ENDPOINTS.API_GET_MY_DEVICES,
    onSuccess: (response) => {
      // console.log(response?.data, "FROM USE get device");
      setItem(USER.FIXBOT_USER_DEVICES, response?.data);
    },
    onError: (err) => {
      // console.log(err, "From useGetDevices hooks");
    },
  });
}

export default useGetDevices;
