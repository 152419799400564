import React from "react";
import { AuthWrapper } from "../../../containers";
import HaveDongle from "./have-dongle";
import NoDongle from "./no-dongle";
import InputDongleId from "./input-dongle";
import { Stack } from "@chakra-ui/react";

const VerifyDongle = () => {
  const [verifyState, setVerifyState] = React.useState("have-dongle");

  return (
    <AuthWrapper isVerify>
      <Stack spacing={8}>
        {verifyState === "have-dongle" && (
          <HaveDongle
            verifyState={verifyState}
            setVerifyState={setVerifyState}
          />
        )}
        {verifyState === "no-dongle" && (
          <NoDongle verifyState={verifyState} setVerifyState={setVerifyState} />
        )}
        {verifyState === "input-dongle" && (
          <InputDongleId
            verifyState={verifyState}
            setVerifyState={setVerifyState}
          />
        )}
      </Stack>
    </AuthWrapper>
  );
};

export default VerifyDongle;
