import React from "react";
import { Box, Button, Stack, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import sx from "./index.module.css";
import { FadeInPage } from "../../components";

const AuthWrapper = ({ children, isLogin, isVerify, showNav = true }) => {
  const navigate = useNavigate();

  const routeHandler = () => {
    isLogin ? navigate("/signup") : navigate("/login");
  };

  return (
    <Stack w="full" minH="100vh" className={sx.auth_wrapper}>
      {showNav && (
        <Box borderBottom={isVerify ? "1px solid rgba(0,0,0,.1)" : 0}>
          <Stack
            w="full"
            maxW="1200px"
            mx="auto"
            direction="row"
            py={3}
            px={6}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Image
                maxH="35px"
                objectFit="cover"
                src="/Images/newlogo.png"
                alt="fixbot logo"
              />
            </Box>
            {isVerify ? null : (
              <Box>
                <Button
                  borderColor="fixbot"
                  rounded={4}
                  _hover={{ bg: "fixbot", color: "#fff" }}
                  onClick={routeHandler}
                >
                  {isLogin ? "Sign up" : "Sign in"}
                </Button>
              </Box>
            )}
          </Stack>
        </Box>
      )}
      <Stack justify="center" flex={3} p={{ base: 2, md: 4 }} w="100%">
        <FadeInPage>{children}</FadeInPage>
      </Stack>
    </Stack>
  );
};

export default AuthWrapper;
