import React from "react";
import { HStack, Text, Stack, Heading, Button } from "@chakra-ui/react";
import useForgotPassword from "../../hooks/auth/useForgotPassword";
import { AuthWrapper } from "../../../containers";
import { Input, Button as CustomButton, IfElse } from "../../../components";
import { useNavigate } from "react-router-dom";
import useValidateAuth from "../../hooks/auth/useValidateAuthFields";
import useErrorHandler from "../../../lib/hooks/useErrorHandler";
import EmailSent from "./EmailSent";

const Login = () => {
  const navigate = useNavigate();
  const authForgotPassword = useForgotPassword();
  useErrorHandler(
    authForgotPassword,
    "Link Sent Successfully, Check your email",
    "Forgot password Error"
  );

  const goToLogin = () => navigate(-1);

  const handleReset = (values) => {
    authForgotPassword.mutateAsync(values);
  };

  const formik = useValidateAuth(
    {
      email: "",
    },
    handleReset
  );

  return (
    <AuthWrapper isVerify>
      <IfElse
        ifOn={
          authForgotPassword.status === "loading" ||
          (!authForgotPassword.data && !authForgotPassword.error)
        }
        ifOnElse={authForgotPassword.status === "success"}
        onElse={<EmailSent />}
      >
        <Stack
          maxW="550px"
          w="96%"
          margin="auto"
          alignItems="stretch"
          spacing={8}
          as="form"
          onSubmit={formik.handleSubmit}
        >
          <Stack textAlign="center">
            <Heading
              as="h2"
              fontSize={{ base: "1.4rem", md: "1.8rem" }}
              textAlign="center"
            >
              Forgot Password
            </Heading>
            <Text>How do you want to reset your forgotten password?</Text>
          </Stack>
          <Stack bg="#fff" px={{ base: 4, md: 8 }} py={8} spacing={5}>
            <Stack mb={6}>
              <Input
                name="email"
                label="email address"
                placeholder="Enter your email"
                type="email"
                {...formik.getFieldProps("email")}
                touched={formik.touched.email}
                errors={formik.errors.email}
              />
            </Stack>
            <HStack>
              <Button flex={2} py={6} onClick={goToLogin}>
                Cancel
              </Button>
              <CustomButton
                type="submit"
                flex={4}
                py={6}
                isLoading={
                  authForgotPassword.isLoading && !authForgotPassword.error
                }
              >
                Send Link
              </CustomButton>
            </HStack>
          </Stack>
        </Stack>
      </IfElse>
    </AuthWrapper>
  );
};

export default Login;
