import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { PAGES } from "../../constants";
import { getUserData } from "../../utils/utils";
import { isExpired } from "react-jwt";
import { useToast } from "@chakra-ui/react";

const AuthorizedPages = () => {
  const toast = useToast();

  if (!getUserData()?.token || isExpired(getUserData()?.token)) {
    toast.closeAll();
    toast({
      title: `Server Error`,
      description: `Expired/No token`,
      status: "error",
      position: "top-right",
      duration: 5000,
      isClosable: true,
    });
    return <Navigate to={PAGES.LOGIN_PAGE} />;
  }

  return <Outlet />;
};

export default AuthorizedPages;
