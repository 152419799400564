import React from "react";
import { Button, Stack, Heading, Text, Icon, Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../../constants";
import { BsPersonCircle } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import useResendMail from "../../hooks/auth/useResendMail";
import { getUserData } from "../../../utils/utils";
import useErrorHandler from "../../../lib/hooks/useErrorHandler";

function Welcome({
  news = "Account Verified",
  message = "Account Verification Successful",
  email = "",
  to,
  failed = false,
  ...others
}) {
  const resendMail = useResendMail();
  useErrorHandler(resendMail, "Link Sent");

  const userData = getUserData();

  const sendMail = () => resendMail.mutateAsync();

  const navigate = useNavigate();
  const goToAuth = () => {
    if (!userData) {
      navigate(PAGES.LOGIN_PAGE);
      return;
    }
    if (failed) {
      sendMail();
    } else {
      navigate(PAGES.LOGIN_PAGE);
    }
  };

  return (
    <React.Fragment>
      <Stack
        w="90%"
        maxW="400px"
        mx="auto"
        p={10}
        bg="#fff"
        rounded={6}
        alignItems="center"
        spacing={8}
        border="1px solid rgba(0,0,0,0.1)"
        {...others}
      >
        <Stack textAlign="center">
          <Heading as="h3" size="md" color={!failed && "whatsapp.600"}>
            {message}
          </Heading>
          <Text>
            {failed
              ? "Token expired"
              : "Use your new account to login to fixbot"}
          </Text>
        </Stack>
        <Stack alignItems="center" py={5}>
          <Icon
            as={failed ? ImCross : BsPersonCircle}
            boxSize={10}
            color={failed ? "red.500" : "gray.400"}
          />
          <Box>{!failed && <Text>{email}</Text>}</Box>
        </Stack>
        <Stack mt={10} w="full">
          <Button
            onClick={goToAuth}
            py={4}
            w="full"
            px={10}
            fontSize="sm"
            variant="solid"
            bg="gray.900"
            _hover={{ bg: "gray.700" }}
            color="#fff"
            rounded="full"
            isLoading={resendMail.isLoading}
          >
            {failed
              ? `${userData ? "Resend Mail" : "Login to request another mail"}`
              : "Continue"}
          </Button>
        </Stack>
      </Stack>
    </React.Fragment>
  );
}

export default Welcome;
