import useCustomMutation from "../../../lib/hooks/useCustomMutation";
import ENDPOINTS from "../../../constants";

function useAuthSignUp() {
  return useCustomMutation({
    endpoint: ENDPOINTS.API_AUTH_SIGNUP,
    method: "POST",
    onError: (err) => {
      console.log(err);
    },
  });
}

export default useAuthSignUp;
