import useCustomQuery from "../../../lib/hooks/useCustomQuery";
import ENDPOINTS from "../../../constants";

function useGetSchedule() {
  return useCustomQuery({
    endpoint: ENDPOINTS.API_GET_SCHEDULE,
    onSuccess: (response) => {
      // console.log(response?.data, "FROM USE get device");
    },
    onError: (err) => {
      // console.log(err, "From useGetSchedule hooks");
    },
  });
}

export default useGetSchedule;
