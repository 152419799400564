import React from "react";
import { Stack, Heading, Text } from "@chakra-ui/react";
import SuccessAnim from "../../../animation/Success";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../../constants";

function Welcome() {
  const navigate = useNavigate();

  React.useEffect(() => {
    setTimeout(() => {
      navigate(PAGES.LOGIN_PAGE);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Stack
        alignItems="center"
        w="90%"
        maxW="350px"
        mx="auto"
        p={10}
        bg="#fff"
        rounded={10}
        border="1px solid rgba(0,0,0,0.1)"
        spacing={6}
      >
        <Stack alignItems="center">
          <SuccessAnim />
          {/* <Text>{news}</Text> */}
        </Stack>
        <Stack alignItems="center" spacing={4} pb={4} pt={3}>
          <Heading fontSize="26px">Signup Successful</Heading>
          <Text fontSize="16px">Redirecting to Login...</Text>
        </Stack>
      </Stack>
    </React.Fragment>
  );
}

export default Welcome;
