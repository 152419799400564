import React from "react";
import { motion } from "framer-motion";
import { Box } from "@chakra-ui/react";

const MotionBox = motion(Box);

function FadeInPage({ children, ...others }) {
  return (
    <MotionBox
      initial={{ opacity: 0.3 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      {...others}
    >
      {children}
    </MotionBox>
  );
}

export default FadeInPage;
