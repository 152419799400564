import React from "react";
import { Stack, Heading } from "@chakra-ui/react";
import WelcomeAnim from "../../../animation/Welcome";
import { useNavigate } from "react-router-dom";
import { PAGES, USER } from "../../../constants";
import useGetDevices from "../../hooks/devices/useGetDevices";
import useLocalStore from "../../hooks/socket/useLocalStore";
import { getUserData } from "../../../utils/utils";

function Welcome({
  news = "Account Verified",
  message = "Welcome back ",
  to,
  ...others
}) {
  const navigate = useNavigate();
  const getDevice = useGetDevices();
  const userData = getUserData();
  const { setItem } = useLocalStore();

  React.useEffect(() => {
    const goVerify = () => navigate(PAGES.VERIFY_PAGE);
    if (getDevice.status === "success" && getDevice.data) {
      setItem(USER.FIXBOT_USER_DEVICES, getDevice.value);
      setTimeout(() => {
        to && getDevice?.value?.length !== 0
          ? navigate(to, { replace: true })
          : goVerify();
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDevice.isSuccess, getDevice.data]);

  return (
    <React.Fragment>
      <Stack
        alignItems="center"
        w="90%"
        maxW="350px"
        mx="auto"
        p={10}
        bg="#fff"
        rounded={10}
        border="1px solid rgba(0,0,0,0.1)"
        spacing={6}
        {...others}
      >
        <Stack alignItems="center">
          <WelcomeAnim />
          {/* <Text>{news}</Text> */}
        </Stack>
        <Heading as="h3" fontSize="1.3rem" color="gray.500">
          {message}
          {userData?.lastname}
        </Heading>
      </Stack>
    </React.Fragment>
  );
}

export default Welcome;
