import React from "react";
import { Box, Button } from "@chakra-ui/react";
import FadeInPage from "../FadeInPage/FadeInPage";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { useState } from "react";

const HalfCircle = ({ setState, init }) => {
  const [number, setNumber] = useState(0);
  const [color, setColor] = useState("rgba(138, 141, 140, 0.58)");
  const fetchData = () => {
    setNumber(0);
    setColor("#00A344");
    setState(true);
    init();
  }
  return (
    <FadeInPage w="100%">
      <Box
        bg="#fff"
        borderRadius="14px"
        h="100%"
        w="100%"
        d={{ sm: "block", md: "flex", lg: "flex", xl: "flex", base: "block" }}
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
        p={{ sm: "1rem", md: "1rem", lg: "1.5rem", xl: "1.5rem", base: "1rem" }}
      >
        <Box
          w="100%"
          d="flex"
          justifyContent="center"
          // overflowX="auto"
          alignItems="center"
          my={{ sm: "1rem", md: "1rem", lg: "", xl: "", base: "1rem" }}
        >
          <SemiCircleProgressBar
            percentage={number}
            stroke="#FF2A1F"
            background={color}
            diameter={250}
            strokeWidth={30}
            showPercentValue
          />
        </Box>

        <Button
          bg="#213654"
          borderRadius="14px"
          color="#fff"
          w={{
            sm: "100%",
            md: "220px",
            lg: "220px",
            xl: "220px",
            base: "100%",
          }}
          height="50px"
          onClick={fetchData}
        >
          Run Diagnostics
        </Button>
      </Box>
    </FadeInPage>
  );
};

export default HalfCircle;
