import { useQuery } from "react-query";
import axios from "axios";
import { getUserData } from "../../utils/utils";

// queryClient.getQueryData(["character", char.id]);
export const secureRequestHandler = ({
  url,
  method = "get",
  body = undefined,
}) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getUserData()?.token}`,
      "Content-Type": "application/json",
    },
  };

  method = method.toLocaleLowerCase();
  if (method === "get") {
    //dont include body in GET request request will fail
    return axios.get(url, {
      params: {
        ...body,
      },
      ...config,
    });
  }

  if (method === "delete") {
    return axios.delete(url, {
      params: {
        ...body,
      },
      ...config,
    });
  }

  return axios[method](url, body, config);
};

export function getQueryAction(payload) {
  const { endpoint, method, body, headers } = payload;
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_STAGING_API_URL
      : process.env.REACT_APP_PRODUCTION_API_URL;

  const url = baseUrl + endpoint;

  return {
    //@ts-ignore
    queryFn: () => {
      return secureRequestHandler({
        url,
        method,
        body,
        headers,
      });
    },

    ...payload,
  };
}

function useCustomQuery(data) {
  const { queryFn, queryKey, endpoint, initialData, then, select, ...others } =
    getQueryAction(data);
  const queryResult = useQuery({
    queryFn,

    queryKey: endpoint,
    onSuccess: (response) => {
      then?.(response);

      if (!queryResult.isPreviousData && !queryResult.isPlaceholderData) {
      }
    },
    onError: (error) => {
      // console.log(error);
    },
    onSettled: (response) => {
      // console.log("Settled");
      // toast({
      //   title: "Succesful.",
      //   description: `Fetched user details`,
      //   status: "success",
      //   position: "top-right",
      //   duration: 5000,
      //   isClosable: true,
      // });
    },
    refetchOnWindowFocus: false,
    ...others,
  });

  return { ...queryResult, value: queryResult?.data?.data };
}

export default useCustomQuery;
