import React from "react";
import classes from "./dashboard.module.css";
import {
  StackedCard as CarCard,
  GoalCard,
  Map,
  Button,
  TimeLine,
} from "../../../components";
import { StatsCardWrapper, DashboardWrapper } from "../../../containers";
import statsData from "../../../constants/statsData";
import { useNavigate } from "react-router-dom";
import { Box, Stack, Text, Heading } from "@chakra-ui/react";
import useGetCanbusData from "../../hooks/socket/useGetCanbusData";
import useLocalStore from "../../hooks/socket/useLocalStore";
import { useSocketContext } from "../../../lib/context/global/SocketContext";
import { USER } from "../../../constants";

function DashboardLanding() {
  const navigate = useNavigate();
  const canbus = useGetCanbusData();
  const { getItem } = useLocalStore();
  const { location, firstLocation } = useSocketContext();
  const [isLoading, setLoading] = React.useState(true);
  const deviceData = getItem(USER.FIXBOT_USER_DEVICES);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    // <FadeInPage>
    <DashboardWrapper
      bottomButton={
        <Button variant="primary" onClick={() => navigate("/my/diagnostics")}>
          Run Diagnostics
        </Button>
      }
      headerComp={
        <Stack spacing={2}>
          <Heading fontSize="2rem">Dashboard</Heading>
          <Text>Avoid breakdowns, detect your car faults on time.</Text>
        </Stack>
      }
    >
      <Box className={classes.dashboard__landing} overflowX="hidden">
        <Box
          display={{ base: "block", md: "none" }}
          mb={{ base: "10px", md: "0" }}
        >
          {canbus && <StatsCardWrapper stats={statsData(canbus)} />}
          {!canbus && isLoading && <StatsCardWrapper stats={null} />}
          {!isLoading && !canbus && (
            <Stack
              h="28"
              rounded={8}
              mb={2}
              justifyContent={"center"}
              alignItems="center"
              w="full"
              bg="#fff"
              p={4}
              textAlign="center"
            >
              <Text color="fixbot">
                No data from dongle <strong>OR</strong> No dongle connected to
                this account
              </Text>
            </Stack>
          )}
        </Box>
        <div className={classes.dashboard__mainSection}>
          <CarCard
            img={
              deviceData.length !== 0 && deviceData[0].image
                ? deviceData[0].image
                : "https://images.unsplash.com/photo-1592805723127-004b174a1798?ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8bGFtYm9yZ2luaXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
            }
            carName={
              deviceData.length !== 0 && deviceData[0].make
                ? `${deviceData[0].make} ${deviceData[0].model}`
                : "Coming Soon"
            }
            distance={null}
          />
          <Stack
            h={{ base: "auto", md: "calc(100% - 15px)" }}
            maxW="100%"
            justify="space-between"
            spacing={2}
          >
            <Box display={{ base: "none", md: "block" }}>
              {canbus && <StatsCardWrapper stats={statsData(canbus)} />}
              {!canbus && isLoading && <StatsCardWrapper stats={null} />}
              {!isLoading && !canbus && (
                <Stack
                  w="full"
                  bg="#fff"
                  h="28"
                  rounded={8}
                  mb={2}
                  justifyContent={"center"}
                  alignItems="center"
                  p={4}
                  textAlign="center"
                >
                  <Text color="fixbot">
                    No data from dongle <strong>OR</strong> No dongle connected
                    to this account
                  </Text>
                </Stack>
              )}
            </Box>
            <TimeLine
              end={location}
              start={firstLocation}
              isLoading={isLoading}
            />
          </Stack>
        </div>
        <Stack>
          <Map />
        </Stack>
        {/* <Stack */}
        {/* w="full" */}
        {/* className={classes.dashboard__goals} */}
        {/* direction={{ base: "column", md: "row" }} */}
        {/* justifyContent="space-between" */}
        {/* spacing={{ base: "3", md: "10" }} */}
        {/* > */}
        {/* <GoalCard /> */}
        {/* <GoalCard /> */}
        {/* </Stack> */}
      </Box>
    </DashboardWrapper>
    // </FadeInPage>
  );
}

export default DashboardLanding;
