const ENDPOINTS = {
  API_BASE_URL: process.env.REACT_APP_API_URL,

  // Auth URl
  API_AUTH_LOGIN: "/auth/signin",
  API_AUTH_SIGNUP: "/auth/signup",
  API_AUTH_FORGOT_PASSWORD: "/auth/forgot-password",

  API_AUTH_NEW_PASSWORD: (token) => `/users/password/reset/${token}`,
  API_AUTH_VERIFY_ACCOUNT: "/auth/verify-account",
  API_AUTH_CHANGE_PASSWORD: "/auth/change-password",
  API_AUTH_RESET_PASSWORD: "/auth/reset-password",
  API_AUTH_RESEND_MAIL: "/auth/resend-verify-mail",

  // Device Api
  API_ADD_DEVICES: "/devices",
  API_EDIT_DEVICES: (dongleId) => `/devices/${dongleId}`,
  API_GET_MY_DEVICES: "/devices/my-device",

  // Profile Api
  API_USER_PROFILE: "/users/my-profile",

  // Create schedule for maintenance
  // API_CREATE_SCHEDULE: (dongleId) => `/maintenance/${dongleId}`,
  // API_GET_SCHEDULE: (dongleId) => `/maintenance/my-schedule/${dongleId}`,

  API_CREATE_SCHEDULE: `/maintenance`,
  API_GET_SCHEDULE: `/maintenance/my-schedule`,
  API_DELETE_SCHEDULE: (id) => `/maintenance/${id}`,
};
export const USER = {
  FIXBOT_USER_TOKEN: "__fixbot_user_auth__",
  FIXBOT_USER_GPS: "__fixbot_user_gps__",
  FIXBOT_USER_GPS_FIRST: "__fixbot_user_gps_first__",
  FIXBOT_USER_DRIVER_DATA: "__fixbot_user_driver_data__",
  FIXBOT_USER_CANBUS: "__fixbot_user_canbus__",
  FIXBOT_USER_DEVICES: "__fixbot_user_devices__",
};

export const PAGES = {
  DASHBOARD_PAGE: "/my/dashboard",

  PAYMENT_LINK_PAGE: "/my/payment-link",
  GET_PAID_PAGE: "/my/get-paid",
  GET_PAID_PAGE_SUCCESS: "/my/get-paid/success",
  GET_PAID_PAGE_UPDATE: "/my/get-paid/update",

  LOGIN_PAGE: "/login",
  REGISTER_PAGE: "/signup",
  VERIFY_PAGE: "/verify",

  USER_PROFILE_PAGE: "/my/profile",
};

export default ENDPOINTS;
