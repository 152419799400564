import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import json from "./json/24987-warning.json";
// import json from "./json/73069-error.json";

const WelcomeAnim = () => {
  return <Player autoplay loop src={json} style={{ height: "130px" }}></Player>;
};

export default WelcomeAnim;
