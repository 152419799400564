/* eslint-disable no-undef */
import React from "react";
import {
  GoogleMap,
  Marker,
  Circle,
  MarkerClusterer,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { Stack } from "@chakra-ui/react";
import { containerStyle, center, options } from "./utils/settings";
import useGetGpsData from "../../modules/hooks/socket/useGetGpsData";
import useLocalStore from "../../modules/hooks/socket/useLocalStore";
import { getReverseGeocodingData, generateHouses } from "../../utils/utils";
import { USER } from "../../constants";
import mapImg from "./img.png";

function FixbotMap({ setLocation, setFirstLocation }) {
  const [dir, setDir] = React.useState(null);
  const gpsData = useGetGpsData();
  // Save map in ref if we want to access the map
  const mapRef = React.useRef(null);
  const localStore = useLocalStore();
  // onLoad fxn for the map
  const onLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const unMount = React.useCallback(() => {
    mapRef.current = null;
  }, []);

  const panMapTo = React.useCallback(() => {
    if (gpsData && mapRef.current) {
      // getReverseGeocodingData(+gpsData.latitude, +gpsData.longitude);
      mapRef.current?.panTo({
        lat: +gpsData.latitude,
        lng: +gpsData.longitude,
      });
    }
  }, [gpsData]);

  // const houses = React.useMemo(
  //   () =>
  //     generateHouses(
  //       gpsData
  //         ? {
  //             lat: +gpsData.latitude,
  //             lng: +gpsData.longitude,
  //           }
  //         : center
  //     ),
  //   [gpsData]
  // );

  React.useEffect(() => {
    panMapTo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gpsData]);

  React.useEffect(() => {
    if (gpsData) {
      getReverseGeocodingData(
        +gpsData.latitude,
        +gpsData.longitude,
        setLocation
      );
      getReverseGeocodingData(
        +localStore.getItem(USER.FIXBOT_USER_GPS_FIRST).latitude,
        +localStore.getItem(USER.FIXBOT_USER_GPS_FIRST).longitude,
        setFirstLocation
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gpsData]);

  // const fetchdirections = React.useCallback((house) => {
  //   // console.log(house, "houses");
  //   if (!gpsData) return;
  //   // eslint-disable-next-line no-undef
  //   const service = new google.maps.DirectionsService();
  //   // console.log(service);
  //   service.route(
  //     {
  //       origin: house,
  //       // waypoints: [
  //       //   {
  //       //     location: houses[0],
  //       //     stopover: false,
  //       //   },
  //       //   {
  //       //     location: houses[5],
  //       //     stopover: false,
  //       //   },
  //       // ],
  //       destination: {
  //         lat: +gpsData.latitude,
  //         lng: +gpsData.longitude,
  //       },
  //       // eslint-disable-next-line no-undef
  //       travelMode: google.maps.TravelMode.DRIVING,
  //     },
  //     (result, status) => {
  //       // console.log("was called??");
  //       if (status === "OK" && result) {
  //         setDir(result);
  //       }
  //     }
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Stack h="full">
      <GoogleMap
        mapContainerStyle={containerStyle}
        options={options}
        center={
          gpsData
            ? {
                lat: +gpsData.latitude,
                lng: +gpsData.longitude,
              }
            : center
        }
        zoom={18}
        onLoad={onLoad}
        onUnMount={unMount}
      >
        {dir && <DirectionsRenderer directions={dir} />}
        {gpsData ? (
          <>
            <Marker
              title={`Your Current location ${
                gpsData.latitude + " " + +gpsData.longitude
              }`}
              // icon="https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
              icon={mapImg}
              position={{ lat: +gpsData.latitude, lng: +gpsData.longitude }}
            />
            {/* <MarkerClusterer>
              {(clusterer) =>
                houses.map((house) => (
                  <Marker
                    key={house.lat + house.lng}
                    position={house}
                    clusterer={clusterer}
                    onClick={() => {
                      fetchdirections(house);
                    }}
                  />
                ))
              }
            </MarkerClusterer> */}

            <Circle
              center={{ lat: +gpsData.latitude, lng: +gpsData.longitude }}
              radius={15000}
              options={closeOptions}
            />
            <Circle
              center={{ lat: +gpsData.latitude, lng: +gpsData.longitude }}
              radius={30000}
              options={middleOptions}
            />
            <Circle
              center={{ lat: +gpsData.latitude, lng: +gpsData.longitude }}
              radius={450000}
              options={farOptions}
            />
          </>
        ) : null}
      </GoogleMap>
    </Stack>
  );
}

export default FixbotMap;

const defaultOptions = {
  strokeOpacity: 0.5,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
};

const closeOptions = {
  ...defaultOptions,
  zIndex: 3,
  fillOpacity: 0.08,
  strokeColor: "#8bc34a",
  fillColor: "#8bc34a",
};

const middleOptions = {
  ...defaultOptions,
  zIndex: 2,
  fillOpacity: 0.05,
  strokeColor: "#fbc02d",
  fillColor: "#fbc02d",
};

const farOptions = {
  ...defaultOptions,
  zIndex: 1,
  fillOpacity: 0.05,
  strokeColor: "#ff5252",
  fillColor: "#ff5252",
};
