import React from "react";
import {
  AvatarBadge,
  VStack,
  Divider,
  Stack,
  Box,
  HStack,
  Avatar,
  Text,
} from "@chakra-ui/react";
import { FadeInPage, Input } from "../../../components";
import useGetDevices from "../../hooks/devices/useGetDevices";

function CarSettings() {
  const dongleDevices = useGetDevices();

  return (
    <FadeInPage w="full" display="grid" placeItems="center">
      <Box w="full" maxW="700px" borderRadius={10} bg="white" mb="20px">
        <VStack w="full" pb="30px">
          <Box w="92%">
            <HStack
              py="20px"
              mx="auto"
              maxW="500px"
              w="full"
              justifyContent="flex-start"
            >
              <Avatar
                size="lg"
                src="https://images.unsplash.com/photo-1592805723127-004b174a1798?ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8bGFtYm9yZ2luaXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
              >
                <AvatarBadge bg="#ED5252" boxSize="1em" />
              </Avatar>
              <Box>
                <Text as="strong" fontSize="18px">
                  Mercedes Benz c300
                </Text>
                <Text fontSize="sm" color="#68768B">
                  #
                  {dongleDevices?.value && dongleDevices?.value.length !== 0
                    ? dongleDevices?.value[0].dongleId
                    : "No Dongle"}
                </Text>
              </Box>
            </HStack>
          </Box>
          <Divider />
          <Box w="92%" maxW="500px" my={5} mx="auto">
            <HStack justifyContent="flex-start" mt="15px" w="full" mb="25px">
              <Text as="strong" fontSize="16px" textTransform="uppercase">
                Car Information
              </Text>
            </HStack>

            <Stack spacing={6}>
              <Input label="Car Name" placeholder="Mercedes Benz c300" />
              <Input
                label="Dongle ID"
                placeholder={
                  dongleDevices?.value
                    ? dongleDevices?.value
                        ?.map(({ dongleId }) => dongleId)
                        .join(", ")
                    : ""
                }
                // disabled
                isLoading={dongleDevices.isLoading}
                bg="#F4F8FB"
              />
              <Input
                label="Car Plate Number"
                placeholder="#2310"
                bg="#F4F8FB"
              />
              <Input label="Car ID" placeholder="#2310" bg="#F4F8FB" />
              <Input
                label="Car Vin"
                placeholder="Car vin goes here"
                bg="#F4F8FB"
              />
              <Input
                label="Car Location"
                placeholder="Elm Ave Street 81, Best Western Grosvenor"
                bg="#F4F8FB"
              />
            </Stack>
          </Box>
        </VStack>
      </Box>
    </FadeInPage>
  );
}

export default CarSettings;
