import React from "react";
import {
  VStack,
  Box,
  HStack,
  Stack,
  Avatar,
  Text,
  AvatarBadge,
  Divider,
} from "@chakra-ui/react";
import { FadeInPage, Input, Button } from "../../../components";
import useGetProfile from "../../hooks/account/useGetProfile";
import useEditProfile from "../../hooks/account/useEditProfile";
import useValidateAuthFields from "../../hooks/auth/useValidateAuthFields";
import { getUserName } from "../../../utils/utils";

function PersonalSettings() {
  const userDetails = useGetProfile();
  const editProfile = useEditProfile();

  const updateProfile = (values) => {
    editProfile.mutateAsync(values).then((res) => {
      console.log(res);
      userDetails.refetch();
    });
  };

  const formik = useValidateAuthFields(
    {
      firstname: "",
      lastname: "",
      phoneNumber: "",
      email: "",
    },
    updateProfile
  );

  // console.log(formik);
  React.useEffect(() => {
    if (userDetails.isSuccess && userDetails.data) {
      formik.setValues({
        firstname: userDetails?.value?.firstname,
        lastname: userDetails?.value?.lastname,
        email: userDetails?.value?.email,
        phoneNumber: userDetails?.value?.phoneNumber,
      });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [userDetails.isSuccess]);

  return (
    <FadeInPage w="full" display="grid" placeItems="center">
      <Box w="full" maxW="700px" borderRadius={10} bg="white" mb="20px">
        <VStack w="full">
          <Box w="92%">
            <HStack
              py="20px"
              w="full"
              maxW="500px"
              mx="auto"
              spacing={3}
              justifyContent="flex-start"
            >
              <Avatar
                size="xl"
                // src="https://bit.ly/dan-abramov"
                name={getUserName(userDetails)}
              >
                <AvatarBadge bg="#ED5252" boxSize="1em" />
              </Avatar>
              <Box>
                <Text as="strong" fontSize="18px">
                  {getUserName(userDetails)}
                </Text>
                <Text mt={-1} fontSize="sm" color="#68768B">
                  {userDetails?.value?.lastname}
                </Text>
              </Box>
            </HStack>
          </Box>
          <Divider />
          <Box w="full" as="form" onSubmit={formik.handleSubmit}>
            <Box maxW="500px" w="92%" my={5} mx="auto">
              <HStack justifyContent="flex-start" mt="15px" w="full" mb="25px">
                <Text as="strong" fontSize="16px" textTransform="uppercase">
                  Personal Information
                </Text>
              </HStack>
              <Stack spacing={6} mb="30px">
                <Input
                  label="First Name"
                  name="firstname"
                  {...formik.getFieldProps("firstname")}
                  touched={formik.touched.firstname}
                  errors={formik.errors.firstname}
                  isLoading={userDetails.isLoading || userDetails.isRefetching}
                />
                <Input
                  label="Last Name"
                  name="lastname"
                  {...formik.getFieldProps("lastname")}
                  touched={formik.touched.lastname}
                  errors={formik.errors.lastname}
                  isLoading={userDetails.isLoading || userDetails.isRefetching}
                />
                <Input
                  label="Phone Number"
                  name="phoneNumber"
                  {...formik.getFieldProps("phoneNumber")}
                  touched={formik.touched.phoneNumber}
                  errors={formik.errors.phoneNumber}
                  isLoading={userDetails.isLoading || userDetails.isRefetching}
                />
                <Input
                  label="Email"
                  name="email"
                  {...formik.getFieldProps("email")}
                  touched={formik.touched.email}
                  errors={formik.errors.email}
                  isLoading={userDetails.isLoading || userDetails.isRefetching}
                  disabled
                />
              </Stack>
            </Box>
            <HStack
              w="full"
              pr="20px"
              py="20px"
              justifyContent="flex-end"
              borderTop="1px solid rgba(0,0,0,0.1)"
            >
              <Button
                px="30px"
                type="submit"
                isLoading={editProfile.isLoading || userDetails.isLoading}
              >
                Save
              </Button>
            </HStack>
          </Box>
        </VStack>
      </Box>
    </FadeInPage>
  );
}

export default PersonalSettings;
