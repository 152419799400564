import { Fade } from "@chakra-ui/react";
import React from "react";

const IfElse = ({
  children = null,
  elseThen = null,
  onElse = null,
  ifOn = false,
  ifOnElse = true,
}) => {
  return (
    <>
      {ifOn && (
        <Fade in="true" unmountOnExit>
          {children}
        </Fade>
      )}

      {ifOnElse && (
        <Fade in="true" unmountOnExit>
          {onElse}
        </Fade>
      )}

      {!ifOn && elseThen && !ifOnElse ? (
        <Fade in="true" unmountOnExit>
          {elseThen}
        </Fade>
      ) : null}
    </>
  );
};

export default IfElse;
