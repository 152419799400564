import React from "react";
import { Text, Box, Image, HStack, Skeleton } from "@chakra-ui/react";

function StatsCard({ img, title, text, isLoaded = true }) {
  return (
    <Skeleton w="full" rounded={6} minW="max-content" isLoaded={isLoaded}>
      <Box bg={{ base: "#fff" }} p={3} w="full" rounded={6} minW="max-content">
        <HStack spacing={2}>
          <Box
            rounded="full"
            w="40px"
            h="40px"
            display="grid"
            placeItems="center"
            bg={{ base: "#ebf3f3" }}
          >
            <Image src={img} maxW="20px" h="20px" />
          </Box>
          <Box>
            <Text
              mb={-1}
              fontSize="sm"
              textTransform="capitalize"
              color="#B5AFAF"
            >
              {title}
            </Text>
            <Text fontSize="xs" as="strong" color="fixbot" isTruncated>
              {text}
            </Text>
          </Box>
        </HStack>
      </Box>
    </Skeleton>
  );
}

export default StatsCard;
