import React from "react";
import { Stack, VStack, Box, HStack, Text } from "@chakra-ui/react";
import { FadeInPage, Input, Button } from "../../../components";
import useChangePassword from "../../hooks/auth/useChangePassword";
import useValidateAuthFields from "../../hooks/auth/useValidateAuthFields";

function PasswordSetting() {
  const changePassword = useChangePassword();

  const updatePassword = (values) => {
    changePassword.mutateAsync({ password: values.newPassword }).then((res) => {
      formik.resetForm();
    });
  };

  const formik = useValidateAuthFields(
    {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    updatePassword
  );

  return (
    <FadeInPage w="full" display="grid" placeItems="center">
      <Box w="full" maxW="700px" borderRadius={10} bg="white" mb="20px">
        <VStack w="full" pb="30px" as="form" onSubmit={formik.handleSubmit}>
          <Box
            py="20px"
            borderBottom="1px solid rgba(0,0,0,0.1)"
            w="full"
          ></Box>
          <Box w="92%" maxW="600px" my={5} mx="auto">
            <HStack justifyContent="flex-start" mt="15px" w="full" mb="25px">
              <Text as="strong" fontSize="16px" textTransform="uppercase">
                Change Password
              </Text>
            </HStack>

            <Stack maxW="500px" spacing={6}>
              <Input
                label="Enter current password"
                bg="rgba(234, 246, 255, 0.7)"
                name="password"
                {...formik.getFieldProps("password")}
                touched={formik.touched.password}
                errors={formik.errors.password}
              />
              <Input
                label="Enter new password"
                bg="rgba(234, 246, 255, 0.7)"
                name="newPassword"
                {...formik.getFieldProps("newPassword")}
                touched={formik.touched.newPassword}
                errors={formik.errors.newPassword}
              />
              <Input
                label="Confirm new password"
                name="confirmPassword"
                {...formik.getFieldProps("confirmPassword")}
                touched={formik.touched.confirmPassword}
                errors={formik.errors.confirmPassword}
                bg="rgba(234, 246, 255, 0.7)"
              />
            </Stack>
          </Box>

          <HStack w="full" pr="20px" pt="10px" justifyContent="flex-end">
            <Button type="submit" px={10} isLoading={changePassword.isLoading}>
              Save Changes
            </Button>
          </HStack>
        </VStack>
      </Box>

      <Box w="full" maxW="700px" borderRadius={10} bg="white" mb={5} pb={5}>
        <Box w="92%" maxW="500px" my={5} mx="auto">
          <Stack my={7}>
            <Text as="strong" fontSize="16px" textTransform="uppercase">
              Set password recovery questions?
            </Text>
          </Stack>

          <Stack mb={5}>
            <VStack>
              <Input
                label="Question 1"
                placeholder="What is your employee ID number?"
              />
            </VStack>
            <HStack>
              <Text fontSize="sm">Answer</Text>
              <Input maxW="250px" size="sm" />
            </HStack>
          </Stack>
          <Stack mb={5}>
            <VStack>
              <Input
                label="Question 2"
                type="password"
                placeholder="What is your car ID number?"
              />
            </VStack>
            <HStack>
              <Text fontSize="sm">Answer</Text>
              <Input maxW="250px" size="sm" />
            </HStack>
          </Stack>
        </Box>

        <HStack w="full" pr="20px" pt="10px" justifyContent="flex-end">
          <Button size="md" variant="primary" px="30px">
            Set Questions
          </Button>
        </HStack>
      </Box>
    </FadeInPage>
  );
}

export default PasswordSetting;
