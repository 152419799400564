import useCustomQuery from "../../../lib/hooks/useCustomQuery";
import ENDPOINTS from "../../../constants";

function useGetProfile() {
  return useCustomQuery({
    endpoint: ENDPOINTS.API_USER_PROFILE,
    onSuccess: (response) => {
      // console.log(response?.data, "USER DETAILS");
    },
    onError: (err) => {
      // console.log(err, "From useGetProfile hooks");
    },
  });
}

export default useGetProfile;
