import useCustomMutation from "../../../lib/hooks/useCustomMutation";
import ENDPOINTS from "../../../constants";
import { useToast } from "@chakra-ui/react";

function useChangePassword() {
  const toast = useToast();

  return useCustomMutation({
    endpoint: ENDPOINTS.API_AUTH_CHANGE_PASSWORD,
    method: "POST",
    onSettled: (response, err) => {
      toast.closeAll();
      !err &&
        toast({
          title: `Successful`,
          description: `Password Updated`,
          status: "success",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      // console.log(response, "from the useEditProfile hook");
    },
    onError: (err) => {
      toast({
        title: `Update Failed`,
        description: `${err?.response?.data?.message}`,
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
      // console.log(err, "from the useEditProfile hook");
    },
  });
}

export default useChangePassword;
