import React from "react";
import {
  Text,
  Tooltip,
  Stack,
  Box,
  HStack,
  CircularProgress,
  CircularProgressLabel,
} from "@chakra-ui/react";
import { trunc } from "../../../utils/utils";

function VehicleCard({ percent, title, isValue }) {
  return (
    <Stack
      bg={{ base: "#fff" }}
      p={3}
      w="full"
      rounded={6}
      maxW={{ base: "95%", sm: "260px" }}
      justifyContent="center"
      alignItems={{ base: "flex-start", sm: "center" }}
      shadow="4px 4px 20px rgba(0, 0, 0, 0.05)"
    >
      <HStack>
        {!isValue ? (
          <CircularProgress
            value={percent}
            thickness="6px"
            color="fixbot"
            size="65px"
          >
            <Tooltip
              label={percent}
              hasArrow
              bg="gray.300"
              color="black"
              aria-label="A tooltip"
            >
              <CircularProgressLabel>
                <Text fontSize="0.8rem">
                  {trunc(percent)}
                  {/* % */}
                </Text>
              </CircularProgressLabel>
            </Tooltip>
          </CircularProgress>
        ) : (
          <Box bg="#ebf3f3" p={2} rounded={3} textAlign="center">
            <Text
              fontSize="sm"
              fontWeight="bold"
              borderBottom="1px solid #213654"
            >
              {percent}
            </Text>
          </Box>
        )}
        <Box>
          <Text fontSize="sm" color="fixbot" letterSpacing="0.05px">
            {title}
          </Text>
        </Box>
      </HStack>
    </Stack>
  );
}

export default VehicleCard;
