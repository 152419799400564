/* eslint-disable no-undef */
import axios from "axios";
import ENDPOINTS, { USER } from "../constants";

export const isTrueUntilFalse = (setter) => {
  let state = true;
  setTimeout(() => {
    setter(false);
    state = false;
  }, 8000);

  return state;
};

export const truncate = (str) =>
  str.length < 15 ? str : `${str.slice(0, 14)}..`;

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export const storeUserData = (data) =>
  sessionStorage.setItem(
    USER.FIXBOT_USER_TOKEN,
    JSON.stringify({ ...data, token: data?.accessToken })
    // JSON.stringify({ token: data?.access_token, isVerified: data?.isVerified })
  );

export const toNum = (num) => (num ? Number(num).toLocaleString() : 0);

export const getUserData = () => {
  const user = sessionStorage.getItem(USER.FIXBOT_USER_TOKEN);

  // console.log(JSON.parse(user));
  return JSON.parse(user);
};

export const updateUserData = (key, value) => {
  const user = JSON.parse(sessionStorage.getItem(USER.FIXBOT_USER_TOKEN));

  sessionStorage.setItem(
    USER.FIXBOT_USER_TOKEN,
    JSON.stringify({ ...user, [key]: value })
  );
};

export const apiRequest = (token) =>
  axios.create({
    baseURL: ENDPOINTS.API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

export const getTotalTips = (tipsArr) =>
  tipsArr
    .map((tip) => tip.amount)
    .reduce((tip, currentValue) => tip + currentValue, 0);

export const toDateString = (serverDate) => {
  let date = new Date(serverDate).toDateString();
  let dateArr = date.split(" ");

  // if (date === new Date().toDateString()) return "Today";

  return `${dateArr[2]} ${dateArr[1]}, ${dateArr[3]}`;
};

export const toTimeString = (serverDate) => {
  if (!serverDate) return "";
  let time = new Date(serverDate)
    .toTimeString()
    .slice(0, 5)
    .split(":")
    .map((a) => +a);

  let hours = time[0];
  let timeMeridian = "am";

  if (hours > 12 && hours < 24) {
    hours = time[0] - 12;
    time[0] = hours;
    timeMeridian = "pm";
  }
  if (hours === 24) {
    time[0] = time[0] - 12;
    timeMeridian = "am";
  }

  if (time[1] <= 9) {
    time[1] = "0" + time[1];
  }
  return `${time.join(":")} ${timeMeridian}`;
};

export const getUserName = (query, whichName) =>
  query?.value
    ? `${
        whichName
          ? query?.value?.[whichName]
          : query?.value?.firstname + " " + query?.value?.lastname
      }`
    : "fixbot user";

export const trunc = (str, len = 7) => str.substr(0, len);

export function getReverseGeocodingData(lat, lng, setterFxn) {
  let latlng = new google.maps.LatLng(lat, lng);
  // This is making the Geocode request
  let geocoder = new google.maps.Geocoder();
  let addressReturned;
  //  setDisabled
  geocoder.geocode({ latLng: latlng }, (results, status) => {
    if (status !== google.maps.GeocoderStatus.OK) {
      // console.log(status, "status grom revers geocoding");
      addressReturned = "";
    }
    // This is checking to see if the Geoeode Status is OK before proceeding
    if (status === google.maps.GeocoderStatus.OK) {
      // console.log(results, "results grom revers geocoding");
      const address = results[0].formatted_address;
      // console.log(address, "address, grom reverss geocoding");
      setterFxn && setterFxn(address);
    }
  });
  return addressReturned;
}

export const generateHouses = (position) => {
  const _houses = [];
  for (let i = 0; i < 5; i++) {
    const direction = Math.random() < 0.5 ? -2 : 2;
    _houses.push({
      lat: position.lat + Math.random() / direction,
      lng: position.lng + Math.random() / direction,
    });
  }
  // console.log(_houses);
  return _houses;
};

export default function debounce(a, b, c) {
  var d, e;
  return function () {
    function h() {
      d = null;
      c || (e = a.apply(f, g));
    }
    var f = this,
      g = arguments;
    return (
      clearTimeout(d), (d = setTimeout(h, b)), c && !d && (e = a.apply(f, g)), e
    );
  };
}

export function removeHTMLTags(str) {
  return str.replace(/<[^>]*>?/gm, "");
}

export function formatDate(timestamp) {
  return new Date(Date.now(timestamp)).toLocaleDateString();
}
