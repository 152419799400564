import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
// import json from "./json/98488-bot-error-404.json";
import json from "./json/96983-404-error.json";

const ErrorAnim404 = () => {
  return <Player autoplay loop src={json} style={{ width: "500px" }}></Player>;
};

export default ErrorAnim404;
