import React, { useState, useEffect } from "react";
import classes from "./Map.module.css";
import { Marker, Map as CustomMap, GoogleApiWrapper } from "google-maps-react";
import { AspectRatio } from "@chakra-ui/react";
import { io } from "socket.io-client";

const Map = React.memo(({ google }) => {
  const [state, setstat] = useState({
    showingInfoWindow: false,
    mapCenter: {
      lat: 49.827291,
      lng: -123.1207375,
    },
  });
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io("https://api.myfixbot.com");
    setSocket(newSocket);

    newSocket.on("connect", () => {
      console.log(newSocket.id, "socket.id"); // x8WIv7-mJelg7on_ALbx
      console.log(newSocket.connected, "socket.id"); // x8WIv7-mJelg7on_ALbx
    });
    console.log(newSocket);

    newSocket.on("monitorDevice", (data) => {
      console.log(data, "monitor device id"); // x8WIv7-mJelg7on_ALbx
    });

    // either by directly modifying the `auth` attribute
    // socket.on("connect_error", () => {
    //   socket.auth.token = "abcd";
    //   socket.connect();
    // });

    // // or if the `auth` attribute is a function
    // const socket = io({
    //   auth: (cb) => {
    //     cb(localStorage.getItem("token"));
    //   },
    // });

    // socket.on("connect_error", () => {
    //   setTimeout(() => {
    //     socket.connect();
    //   }, 1000);
    // });
    return () => newSocket.close();
  }, []);
  return (
    <div className={classes.map}>
      <div>
        <AspectRatio ratio={16 / 9} className={classes.map__inner}>
          <CustomMap
            google={google}
            initialCenter={{
              lat: state.mapCenter.lat,
              lng: state.mapCenter.lng,
            }}
            center={{
              lat: state.mapCenter.lat,
              lng: state.mapCenter.lng,
            }}
          >
            <Marker
              position={{
                lat: state.mapCenter.lat,
                lng: state.mapCenter.lng,
              }}
            />
          </CustomMap>
        </AspectRatio>
      </div>
    </div>
  );
});

export default GoogleApiWrapper({
  apiKey: "AIzaSyD1pkxL93Aui_SU7h7u151CtWVMkufi28o",
})(Map);
