import React from "react";
import classes from "../diagnostics/diagnostics.module.css";
import { DashboardWrapper } from "../../../containers";
import {
  FadeInPage,
  Button,
  FaultDialogue,
} from "../../../components";
import { VStack, Stack, Text, Heading, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import { setAuthToken } from "../../../utils/setAuthToken";


function SingleDiagnostic() {
  const navigate = useNavigate();
  const toast = useToast();
  const [faults, setFaults] = React.useState(null)
  const [token] = React.useState(
    JSON.parse(sessionStorage.getItem("__fixbot_user_auth__")).token
  );
  
  React.useEffect(() => {
    const init = async () => {
      if (token) {
        setAuthToken(token);
        try {
          const res = await axios.get(
            "https://api.myfixbot.com/carmd/diagnoseFaults"
          );
          setFaults(res.data);
          toast({
            title: "Success",
            description: res.data.msg,
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          });
        } catch (err) {
          console.log(err.message);
          if (err.response) {
            toast({
              title: err.response.data.statusCode,
              description: err.response.data.message,
              status: "error",
              duration: 9000,
              isClosable: true,
              position: "top-right",
            });
          }
        }
      }
    };
    init();
  }, [faults, token, toast]);
  
   
  // const data = {
  //   msg: "Diagnosis Completed and ErrorCode DB updated",
  //   data: {
  //     dongleId: "000000000000000000",
  //     errorCodes: {
  //       P0420: {
  //         code: "P0420",
  //         carmd: {
  //           code: "P0420",
  //           desc: "Replace Catalytic Converter(s) with new OE Catalytic Converter(s)",
  //           urgency: 2,
  //           urgency_desc:
  //             "Repair immediately if drivability issues are present. Threat to essential system components if not repaired as soon as possible.",
  //           effect_on_vehicle:
  //             "This condition will prevent the vehicle from running at its optimum efficiency and fuel economy may suffer.",
  //           responsible_system:
  //             "Sensors indicate the catalytic converter is not reducing exhaust gas emissions properly.",
  //           layman_definition:
  //             "Catalyst System Efficiency Below Threshold (Bank 1)",
  //           tech_definition: "Catalyst System Low Efficiency (Bank 1)",
  //           repair: {
  //             difficulty: 3,
  //             hours: 2.3,
  //             labor_rate_per_hour: 106.38,
  //             part_cost: 1967.01,
  //             labor_cost: 244.674,
  //             misc_cost: 25,
  //             total_cost: 2236.684,
  //           },
  //           parts: [
  //             {
  //               desc: "Catalytic Converter",
  //               manufacturer: "",
  //               price: 683.67,
  //               qty: "1",
  //             },
  //             {
  //               desc: "Catalytic Converter",
  //               manufacturer: "",
  //               price: 1283.34,
  //               qty: "1",
  //             },
  //           ],
  //           tsb: [
  //             {
  //               id: 118676,
  //               manufacturer_number: "07-00-89-037K",
  //               desc: "Warranty Administration - Courtesy Transportation and Roadside Assistance Programs",
  //               categories: ["Tools & Hardware"],
  //               file_name: "4824780",
  //               file_url:
  //                 "http://downloads.innova.com/tsb-files/118000/4824780.pdf",
  //               issue_date: "2017-06-23T00:00:00",
  //               updated_date: "2017-10-10T00:00:00",
  //             },
  //           ],
  //         },
  //         decoded: true,
  //         partials: {
  //           repair: false,
  //           diag: false,
  //         },
  //       },
  //       P0422: {
  //         code: "P0422",
  //         carmd: {
  //           code: "P0422",
  //           desc: "Replace Catalytic Converter(s) with new OE Catalytic Converter 2",
  //           urgency: 2,
  //           urgency_desc:
  //             "Repair immediately if drivability issues are present. Threat to essential system components if not repaired as soon as possible.",
  //           effect_on_vehicle:
  //             "This condition will prevent the vehicle from running at its optimum efficiency and fuel economy may suffer.",
  //           responsible_system:
  //             "Sensors indicate the catalytic converter is not reducing exhaust gas emissions properly.",
  //           layman_definition:
  //             "Catalyst System Efficiency Below Threshold (Bank 1)",
  //           tech_definition: "Catalyst System Low Efficiency (Bank 1)",
  //           repair: {
  //             difficulty: 3,
  //             hours: 2.3,
  //             labor_rate_per_hour: 106.38,
  //             part_cost: 1967.01,
  //             labor_cost: 244.674,
  //             misc_cost: 25,
  //             total_cost: 2236.684,
  //           },
  //           parts: [
  //             {
  //               desc: "Catalytic Converter",
  //               manufacturer: "",
  //               price: 683.67,
  //               qty: "1",
  //             },
  //             {
  //               desc: "Catalytic Converter",
  //               manufacturer: "",
  //               price: 1283.34,
  //               qty: "1",
  //             },
  //           ],
  //           tsb: [
  //             {
  //               id: 118676,
  //               manufacturer_number: "07-00-89-037K",
  //               desc: "Warranty Administration - Courtesy Transportation and Roadside Assistance Programs",
  //               categories: ["Tools & Hardware"],
  //               file_name: "4824780",
  //               file_url:
  //                 "http://downloads.innova.com/tsb-files/118000/4824780.pdf",
  //               issue_date: "2017-06-23T00:00:00",
  //               updated_date: "2017-10-10T00:00:00",
  //             },
  //           ],
  //         },
  //         decoded: true,
  //         partials: {
  //           repair: false,
  //           diag: false,
  //         },
  //       },
  //     },
  //     createdAt: Date,
  //     updatedAt: Date,
  //   },
  // };
    return (
      <FadeInPage>
        <DashboardWrapper
          isMdBgWhite
          bottomButton={
            <Button onClick={() => navigate("/dashboard/schedule")}>
              Get a mechanic
            </Button>
          }
          headerComp={
            <Stack spacing={2}>
              <Heading
                cursor="pointer"
                _hover={{ opacity: "0.8" }}
                fontSize={{
                  sm: "1.2rem",
                  md: "1.2rem",
                  lg: "1.8rem",
                  xl: "1.8rem",
                  base: "1.2rem",
                }}
                onClick={() => navigate("/my/diagnostics")}
              >
                <FontAwesomeIcon icon={faChevronLeft} /> Back
              </Heading>
              <Text>Avoid breakdowns, detect your car faults on time.</Text>
            </Stack>
          }
        >
          <div className={classes.diagnostics}>
            <VStack maxW="100%">
              <FaultDialogue fault={faults !== null && faults.data.errorCodes} />
            </VStack>
          </div>
        </DashboardWrapper>
      </FadeInPage>
    );
  }
  
  export default SingleDiagnostic;