import React from "react";
import { Text, Stack, Heading, SimpleGrid } from "@chakra-ui/react";
import { AuthWrapper } from "../../../containers";
import { Input, Button, IfElse } from "../../../components";
import { Link } from "react-router-dom";
import useValidateAuth from "../../hooks/auth/useValidateAuthFields";
import useAuthSignUp from "../../hooks/auth/useAuthSignup";
import useErrorHandler from "../../../lib/hooks/useErrorHandler";
import Welcome from "./Welcome";

function Signup() {
  const authSignup = useAuthSignUp();
  useErrorHandler(authSignup, "Signup Successful", "Signup Error");

  const handleSubmit = (values) => {
    authSignup.mutateAsync(values);
  };

  const formik = useValidateAuth(
    {
      firstname: "",
      lastname: "",
      email: "",
      phoneNumber: "",
      password: "",
    },
    handleSubmit
  );

  return (
    <AuthWrapper isVerify={authSignup.isSuccess && authSignup.data}>
      <IfElse
        ifOn={!(authSignup.isSuccess && authSignup.data)}
        ifOnElse={authSignup.isSuccess && authSignup.data}
        onElse={<Welcome />}
      >
        <Stack
          maxW="550px"
          w="96%"
          margin="auto"
          alignItems="stretch"
          spacing={8}
          as="form"
          onSubmit={formik.handleSubmit}
        >
          <Heading
            as="h2"
            fontSize={{ base: "1.4rem", md: "1.8rem" }}
            textAlign="center"
          >
            Stop guessing, use Fixbot
          </Heading>
          <Stack bg="#fff" px={{ base: 4, md: 8 }} py={8} spacing={6}>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={4}>
              <Input
                name="firstname"
                placeholder="First Name"
                type="text"
                label="First Name"
                {...formik.getFieldProps("firstname")}
                touched={formik.touched.firstname}
                errors={formik.errors.firstname}
              />
              <Input
                name="lastname"
                placeholder="Last Name"
                type="text"
                label="Last name"
                {...formik.getFieldProps("lastname")}
                touched={formik.touched.lastname}
                errors={formik.errors.lastname}
              />
            </SimpleGrid>
            <Input
              name="email"
              placeholder="Enter your email"
              type="email"
              label="email address"
              {...formik.getFieldProps("email")}
              touched={formik.touched.email}
              errors={formik.errors.email}
            />
            <Input
              name="phoneNumber"
              placeholder="Input phone number"
              type="text"
              label="phone number"
              {...formik.getFieldProps("phoneNumber")}
              touched={formik.touched.phoneNumber}
              errors={formik.errors.phoneNumber}
            />
            <Input
              name="password"
              placeholder="Choose password"
              type="password"
              label="Password"
              {...formik.getFieldProps("password")}
              touched={formik.touched.password}
              errors={formik.errors.password}
            />
            <Stack pt={8}>
              <Button
                size="md"
                py={6}
                type="submit"
                // loadingText="Loading"
                isLoading={authSignup.isLoading}
              >
                Sign up
              </Button>
            </Stack>
          </Stack>

          <Stack alignItems="center" mt={4}>
            <Text fontSize="1rem">
              Do you have an account already? {"  "}
              <Link to="/login">
                <Text as="strong" color="fixbot">
                  Sign in
                </Text>
              </Link>
            </Text>
          </Stack>
        </Stack>
      </IfElse>
    </AuthWrapper>
  );
}

export default Signup;
