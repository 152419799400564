import React from "react";
import classes from "./StackedCard.module.css";
import {
  Box,
  Stack,
  Image,
  HStack,
  Text,
  Progress,
  Heading,
  IconButton,
} from "@chakra-ui/react";
import { MdOutlineReplay } from "react-icons/md";

function StackedCard({ width = "auto", distance = "46km/h", img, carName }) {
  return (
    <Box
      w={width}
      h={{ base: "calc(100% - 15px)", lg: "calc(100%)" }}
      mb={4}
      className={classes.stackedcard}
    >
      <Box pos="relative" className={classes.stackedcard__img}>
        {/* <Box
          pos="absolute"
          top="50%"
          right="35%"
          transform="translate(-50%, -50%)"
        >
          <IconButton variant="solid" colorScheme="teal">
            <MdOutlineReplay />
          </IconButton>
        </Box> */}
        <Image src={img} alt="A card" objectFit="cover" w="100%" />
      </Box>

      <Stack px="15px" spacing={4} pt="20px">
        <Box>
          <Heading fontSize="sm">{carName}</Heading>
        </Box>

        <Stack w="full" pb={2}>
          <HStack justifyContent="space-between">
            <Text fontSize="sm">Overall Health Status</Text>
            <Text fontSize="sm">40%</Text>
          </HStack>
          <Progress
            rounded="full"
            colorScheme="gray"
            bg="rgba(255, 255, 255, 0.04)"
            size="sm"
            value={40}
          />
        </Stack>
      </Stack>
    </Box>
  );
}

export default StackedCard;
