import React from "react";
import { Stack, Flex } from "@chakra-ui/react";
import sx from "./index.module.css";
import Error404 from "../../animation/Error404";
import Button from "../CustomButton/CustomButton";

const Notfound = () => {
  return (
    <Flex w="100%" h="100vh" className={sx.not_found}>
      <Stack
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        w="90%"
        mx="auto"
      >
        <Error404 />
        <Button w="max-content" size="md" px={10} py={6}>
          Go to dashboard
        </Button>
      </Stack>
    </Flex>
  );
};

export default Notfound;
